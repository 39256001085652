import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import SocialLogin from "../Class/SocialLogin.ts"; // SocialLogin 클래스 경로
import { FaVideo, FaSistrix, FaBars, FaX } from "react-icons/fa6"; // 햄버거 아이콘 및 닫기 아이콘
import Cookies from "js-cookie";

const Navbar: React.FC = () => {
  // 상태 관리
  const [online, setOnline] = useState(!!Cookies.get("token"));
  const [customerSupportDropdownOpen, setCustomerSupportDropdownOpen] =
    useState(false);
  const [myPageDropdownOpen, setMyPageDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // 햄버거 메뉴 열기 상태
  const timeoutRef = useRef<number | undefined>(undefined);
  const location = useLocation(); // 페이지 경로 정보를 가져옴

  // 로컬 스토리지 변경 감지
  useEffect(() => {
    const handleStorageChange = () => {
      setOnline(!!Cookies.get("token"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // 페이지 이동 시 메뉴 닫기
  useEffect(() => {
    setMenuOpen(false); // 경로가 바뀌면 메뉴를 닫음
  }, [location.pathname]); // 경로가 바뀔 때마다 실행

  const handleCustomerSupportMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setCustomerSupportDropdownOpen(true);
  };

  const handleCustomerSupportMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setCustomerSupportDropdownOpen(false);
    }, 100); // 0.1초의 딜레이
  };

  const handleMyPageMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMyPageDropdownOpen(true);
  };

  const handleMyPageMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setMyPageDropdownOpen(false);
    }, 100); // 0.1초의 딜레이
  };

  const handleLogout = async () => {
    const socialLogin = new SocialLogin("logout");
    await socialLogin.logout();
    window.location.replace("/login");
  };

  // 햄버거 메뉴 토글
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-brand p-2">
      <div className="relative w-11/12 flex justify-between max-w-screen-2xl mx-auto lg:w-4/5">
        <nav className="flex items-baseline gap-x-12 font-semibold py-4">
          <Link
            to="/"
            className="flex items-baseline gap-x-2 text-4xl text-gray-50"
          >
            <FaVideo className="self-end" />
            <span className="font-bold">Poten</span>
          </Link>
          {/* 데스크탑 메뉴 */}
          <div className={`hidden lg:flex flex-row items-baseline gap-x-16`}>
            <Link to="/about" className="text-xl font-semibold text-gray-50">
              About Us
            </Link>
            <Link to="/market" className="text-xl font-semibold text-gray-50">
              마켓
            </Link>
            <div
              className="relative"
              onMouseEnter={handleCustomerSupportMouseEnter}
              onMouseLeave={handleCustomerSupportMouseLeave}
            >
              <button className="text-xl font-semibold text-gray-50">
                고객 지원
              </button>
              {customerSupportDropdownOpen && (
                <div
                  className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10"
                  onMouseEnter={handleCustomerSupportMouseEnter}
                  onMouseLeave={handleCustomerSupportMouseLeave}
                >
                  <Link
                    to="/notice"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    공지사항
                  </Link>
                  <Link
                    to="/FAQ"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    자주 묻는 질문
                  </Link>
                  <Link
                    to="/QandAForm"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    문의하기
                  </Link>
                </div>
              )}
            </div>
          </div>
        </nav>

        {/* 햄버거 아이콘 */}
        <div className="lg:hidden flex items-center">
          <button onClick={toggleMenu} className="text-gray-50 text-2xl">
            {menuOpen ? <FaX /> : <FaBars />}
          </button>
        </div>

        {/* 로그인 상태에 따른 My Page 메뉴 */}
        <div className="hidden lg:flex items-baseline gap-x-4 font-semibold relative p-4">
          <FaSistrix className="text-4xl text-gray-50" />
          {online ? (
            <div
              className="relative"
              onMouseEnter={handleMyPageMouseEnter}
              onMouseLeave={handleMyPageMouseLeave}
            >
              <button className="text-xl font-semibold text-gray-50">
                My Page
              </button>
              {myPageDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <Link
                    to="/mypageCh"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    보유 채널
                  </Link>
                  <Link
                    to="/mypagePoint"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    포인트 현황
                  </Link>
                  <Link
                    to="/mypageProfit"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    실현 손익
                  </Link>
                  <Link
                    to="/mypageTradeEdit"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    내 거래 관리
                  </Link>
                  <Link
                    to="/infoModify"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    개인정보 수정
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    로그아웃
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/Login" className="text-xl font-semibold text-gray-50">
              로그인
            </Link>
          )}
        </div>
      </div>

      {/* 모바일 메뉴 */}
      {menuOpen && (
        <div className="lg:hidden mt-4 bg-brand p-4">
          <Link
            to="/about"
            className="block text-xl font-semibold text-gray-50 mb-4"
          >
            About Us
          </Link>
          <Link
            to="/market"
            className="block text-xl font-semibold text-gray-50 mb-4"
          >
            마켓
          </Link>
          <div className="mb-4">
            <span className="block text-xl font-semibold text-gray-50 w-full text-left">
              고객 지원
            </span>
            <div className="pl-4">
              <Link to="/notice" className="block px-4 py-2 text-gray-50">
                공지사항
              </Link>
              <Link to="/FAQ" className="block px-4 py-2 text-gray-50">
                자주 묻는 질문
              </Link>
              <Link to="/QandAForm" className="block px-4 py-2 text-gray-50">
                문의하기
              </Link>
            </div>
          </div>
          {online ? (
            <div className="mb-4">
              <span className="block text-xl font-semibold text-gray-50 w-full text-left">
                고객 지원
              </span>
              <div className="pl-4">
                <Link to="/mypageCh" className="block px-4 py-2 text-gray-50">
                  보유 채널
                </Link>
                <Link
                  to="/mypagePoint"
                  className="block px-4 py-2 text-gray-50"
                >
                  포인트 현황
                </Link>
                <Link
                  to="/mypageProfit"
                  className="block px-4 py-2 text-gray-50"
                >
                  실현 손익
                </Link>
                <Link
                  to="/mypageTradeEdit"
                  className="block px-4 py-2 text-gray-50"
                >
                  내 거래 관리
                </Link>
                <Link to="/infoModify" className="block px-4 py-2 text-gray-50">
                  개인정보 수정
                </Link>
              </div>
              <button
                onClick={handleLogout}
                className="block w-full text-left text-xl font-semibold text-gray-50"
              >
                로그아웃
              </button>
            </div>
          ) : (
            <Link
              to="/Login"
              className="block text-xl font-semibold text-gray-50"
            >
              로그인
            </Link>
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;
