/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../css/modal.css";
import axios from "axios";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_POTEN_URL;

export default function Modal({ isOpen, closeModal, userdata }) {
  const [isPayOpen, setIsPayOpen] = useState(false);
  const [amount, setAmount] = useState(0);

  const handlecloseModal = (e) => {
    e.preventDefault();
    closeModal();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iamport.kr/v1/iamport.js";
    script.async = true;
    document.body.appendChild(script);
  });

  function payClick(pay) {
    setAmount(`${pay}`);
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    // IMP.init("imp22128264"); 쉐릿
    IMP.init("imp22128264");
    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: "mobilians", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `poten_mid_${new Date().getTime()}_${userdata.user.id}`, // 주문번호
      amount: pay, // 결제금액
      name: "POTEN point 결제", // 주문명
      buyer_name: userdata.user.username, // 구매자 이름
      buyer_tel: userdata.profile.phone, // 구매자 전화번호
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  /* 3. 콜백 함수 정의하기 */
  function callback(response) {
    const token = Cookies.get("token") || "";
    console.log("response");
    console.log(response);
    const { success, merchant_uid, error_msg } = response;

    if (success) {
      console.log("yes succcess");
      const formData = new FormData();
      formData.append("merchant_uid", response.merchant_uid);
      formData.append("amount", response.paid_amount);
      formData.append("imp_uid", response.imp_uid);
      formData.append("card_num", response.card_number);

      axios
        .post(`${url}/posts/payments/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          alert(res.data.msg);
          console.log(res.data);

          if (res.data.code === 200) {
            window.location.reload();
          } else {
            alert("network error입니다. 고객센터에 문의해주세요.");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  return (
    <div
      className="modal"
      id="myModal1"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div
        className="relative mx-auto my-8 p-9 w-3/4 rounded-lg border border-slate-200 bg-white lg:w-1/3"
        style={{ display: isPayOpen ? "none" : "block" }}
      >
        <div className="modal-title">
          <button
            className="absolute right-2 top-1 modal-title font-bold text-xl text-brand"
            onClick={handlecloseModal}
          >
            &times;
          </button>
          <h1>포인트 구매</h1>
          <p className="direction">결제 금액을 선택해주세요</p>
          <p>결제 금액 1000원 당 100포인트가 적립됩니다.</p>
        </div>

        <div className="modal-second-form" id="modal-form1">
          <div className="input-container">
            <input
              type="button"
              autoComplete="on"
              className="block flex-1 poin_box border-0 text-sm py-1.5 pl-1 bg-gray-200/30 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
              defaultValue={`+1000 원 충전 :: 1000 Point 추가`}
              onClick={(e) => payClick(1000)}
            />
          </div>
          <div className="input-container">
            <input
              type="button"
              onClick={(e) => payClick(5000)}
              autoComplete="on"
              className="block flex-1 poin_box border-0 text-sm py-1.5 pl-1 bg-gray-200/30 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
              defaultValue={`+5000 원 충전 :: 5000 Point 추가`}
            />
          </div>
          <div className="input-container">
            <input
              type="button"
              autoComplete="on"
              onClick={(e) => payClick(10000)}
              className="block flex-1 poin_box border-0 text-sm py-1.5 pl-1 bg-gray-200/30 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
              defaultValue={`+10000 원 충전 :: 10000 Point 추가`}
            />
          </div>
          <div className="input-container">
            <input
              type="button"
              autoComplete="on"
              onClick={(e) => payClick(50000)}
              className="block flex-1 poin_box border-0 text-sm py-1.5 pl-1 bg-gray-200/30 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
              defaultValue={`+50000 원 충전 :: 50000 Point 추가`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
