import React from "react";

export default function Guidelines() {
  const text = `판매 이용약관 2023. 12. 14. ver

    제1장 총칙
    
    제1조 목적
    본 약관은 주식회사 쉐릿이 제공하는 전문 서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 본 약관은 이용자가 회사가 제공하는 스마트폰, 태블릿기기용 앱 등을 이용하는 경우에도 그 성질에 반하지 않는 한 준용됩니다.
    
    제2조 용어의 정의
    1. "회사"란 "이용자"가 컴퓨터 등 정보통신설비를 이용하여 "정보 등"을 거래할 수 있는 가상의 영업장 및 2항의 "전문 서비스"를 제공하는 주체로, 주식회사 쉐릿을 말합니다.
    2. "전문 서비스"란 "회사"에서 제공하는 광고 대행, 마케팅, 평점 및 리뷰운용, 결제 대행, 기타 "이용자"가 제공하는 "정보 등"의 판매를 돕는 인터넷 관련 서비스를 말합니다.
    3. "정보 등"은 "회사"가 제공하는 "전문 서비스"를 이용하여 "이용자"가 판매하는 재화 또는 정보 일체를 말하며, "콘텐츠"를 포함합니다. 
    4. "이용자"란 회사가 제공하는 "전문 서비스"를 이용하여 자신을 홍보하고 수익을 얻을 목적으로 본 약관에 동의하고 인증받은 자를 의미합니다.
    5. "의뢰인"이란 "이용자"가 판매하는 "정보 등"을 구입할 의사를 가지고 "회사"를 이용하는 이용자를 말합니다.
    6. "유료 광고"란 등록한 "정보 등"의 홍보 효과를 높이기 위해 "이용자"가 "회사"가 제공하는 방식으로 구매하여 사용할 수 있는 광고 상품을 말합니다.
    7. "비즈머니"란 "회사"에서 제공하는 "유료 광고"를 구매하고 그 대가를 지급하는 데 사용할 수 있는 것으로서 "충전 비즈머니"와 "적립 비즈머니"로 구분됩니다. 
    8. "충전 비즈머니"란 유료로 사전에 충전하여 "회사"에서 "유료 광고"를 구매하고 그 대가를 지급하는 데 사용할 수 있는 결제 수단을 말합니다.
    9. "적립 비즈머니"란 "이용자"가 서비스 판매 완료 및 이벤트 참여 등 "회사"가 사전에 고지한 방법에 따라 "회사"로부터 무상으로 적립 받아 사용할 수 있는 "비즈머니"를 말합니다. 
    
    제3조 약관 등의 명시와 설명 및 개정
    1. 회사는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 팩스번호, 이메일 주소, 사업자등록번호, 통신판매업 신고번호 등을 이용자가 쉽게 알 수 있도록 회사의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
    2. 회사는 이용자가 본 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중 정보 등의 공급, 취소 및 환불, 판매 수수료 등과 같은 중요한 내용을 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
    3. 본 약관의 내용은 서비스의 화면에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 이용자 모두에게 그 효력이 발생합니다.
    4. 회사는 필요한 경우 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 7일 전부터 이용자에게 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 피치 못하게 이용자에게 불리한 내용으로 변경할 경우에는 최소한 그 시행일자 30일 전부터 제4조에 규정한 방법으로 개별적으로 알려 드리겠습니다.
    5. 회사는 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 이용자의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.
    6. 회사가 본 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정약관의 공지기간 내에 회사에 송신하여 회사의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
    7. 이용자는 개정약관에 동의하지 않을 권리가 있으며 개정약관에 동의하지 않을 경우 개정약관의 적용을 받는 서비스의 이용이 불가합니다. 이용자는 이용을 중단하고 이용계약의 해지를 선택할 수 있습니다.
    8. 본 약관은 이용자가 본 약관에 동의한 날로부터 이용계약의 해지 시까지 적용하는 것을 원칙으로 합니다. 단, 본 약관의 일부 조항은 이용계약의 해지 후에도 유효하게 적용될 수 있습니다.
    9. 본 약관에서 정하지 아니한 사항 및 해석에 관하여는 약관의 규제 등에 관한 법률 및 기타 서비스와 관련된 관계법령 또는 상관례에 따릅니다.
    
    제2장 서비스 이용
    제4조 이용자에 대한 통지
    1. 회사가 이용자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자가 제공한 이메일 주소, (휴대)전화번호, 쉐릿 안내, 배너, 문자메시지, 알림톡, 고객센터 게시글 등 서비스 내 각종 통지 수단으로 할 수 있습니다.
    2. 회사는 이용자의 권리에 중대한 영향을 미치지 않는 사항에 대해서는 7일 이상 회사가 제공하는 서비스에 공지함으로써 제1항의 통지에 갈음할 수 있습니다.
    
    제5조 이용자 인증 및 평가
    1. 이용자는 회원가입 후 이용자 인증을 거쳐야 판매 활동을 시작할 수 있으며, 인증 유형에 따라 개인 이용자 또는 사업자 이용자로 인증할 수 있습니다.
    2. 개인 이용자 인증은 회사가 정한 인증 양식에 따른 사항을 기입한 후 저장하면 완료됩니다. 
    3. 사업자 이용자 인증은 회사가 정한 인증 양식에 따른 사항을 기입하고 증빙 서류를 첨부하여 저장하면 회사가 이를 확인, 대조 후 승인합니다. 승인이 완료되면 사업자 이용자 인증이 완료됩니다. 단, 이용자가 작성한 정보가 증빙 서류와 불일치하는 경우에는 승인이 거절될 수 있습니다. 
    4. 이용자가 인증 시 등록한 사항에 변경이 있는 경우에는 상당한 기간 내에 회사에 회원 정보를 수정하는 등의 방법으로 그 변경사항을 알려야 하며, 변경된 회원 정보를 회사에 알리지 않아 발생하는 손해에 대한 책임은 이용자에게 있습니다.
    5. 이용자는 이용자 인증 시 회사로부터 수익금을 수령할 계좌를 지정하여야 하며, 회사가 정하는 바에 따라 계좌를 변경할 수 있습니다. 이용자가 지정한 입금계좌의 예금주는 이용자와 동일인(개인 이용자의 경우 가입자 본인 명의, 사업자 이용자의 경우 대표자 혹은 사업장 명의) 임을 원칙으로 합니다.
    6. 회사는 평가제도를 운영하여 이용자의 등급을 결정하는데 사용할 수 있으며, 등급에 따라 이용자는 다른 혜택을 부여 받을 수 있습니다. 회사는 평가 방법, 등급 및 등급별 혜택 등의 내용을 관련 서비스 화면을 통해 공지합니다. 
    
    제6조 판매 게시글 등록
    1. 이용자는 판매 게시글 등록 시 의뢰인이 구매서비스에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게 기재하여야 합니다.
    1) 연락 가능한 번호
    2) 프로필 정보
    3) 판매 가격
    4) 정보 등의 상세 정보
    5) 배송이 필요한 경우 배송비 부담 주체, 발송 방법
    6) 특별한 거래 조건이 있거나 수정, 반품 비용 등 추가되는 비용이 있는 경우 그에 관련된 사항
    2. 회사는 이용자의 판매 게시글 등록이 있으면 이를 검토하여 승인하며, 이용자는 승인된 후에 판매할 수 있습니다.
    3. 이용자는 판매 게시글 등록이 승인된 이후에도 그 내용을 수정할 수 있습니다. 단, 수정되기 이전에 판매된 건에 대해서는 판매 시점의 정보가 적용됩니다.
    4. 이용자는 판매 게시글 등록이 승인된 이후 별도의 기한 없이 판매를 지속할 수 있습니다. 다만, 본 약관에서 정한 바에 따라 회원자격이 정지되거나 서비스의 이용 제한, 기타 필요한 조치가 취해지는 등 판매 활동이 제한될 수 있으며, 이 경우 회사는 판매 가능 여부를 재검토할 수 있습니다.
    
    제7조 이용자의 의무
    1. 판매 활동을 위해 인증한 이용자 정보와 실제 서비스를 제공하는 이용자는 동일해야 합니다. 하도급 행위 등 본 조항에 따른 권리∙의무를 회사의 사전 동의 없이 타인에게 양도, 증여하거나 담보로 제공하는 행위는 금지됩니다.
    2. 이용자는 인증받은 휴대폰 번호와 이용자 정보 내의 연락 가능한 번호를 항상 최신 번호로 유지해야 합니다.
    3. 이용자는 회사가 제공하는 양식에 따라 프로필을 등록해야 하며, 허위 정보 등록은 불가합니다.
    4. 이용자는 회사가 제공하는 양식에 따라 판매 게시글을 등록해야 하며, 서비스 판매가 목적이 아닌 결제·거래 부정 행위 등 다른 목적의 허위등록, 판매 가장등록, 신용카드 결제 시스템 또는 통신과금 시스템만을 이용하기 위한 등록은 불가합니다.
    5. 이용자는 판매 게시글을 임의로 변경, 수정하지 않고 회사가 승인한 내용으로 서비스를 제공합니다.
    6. 이용자는 회사가 서비스의 판매 촉진과 신뢰도 상승을 위해 요청한 프로필/이미지 등의 등록 기준을 확인하고, 이와 관련한 회사의 요청사항이 있는 경우 협조해야 합니다.
    7. 이용자는 가격 정보 및 기본 옵션, 서비스 설명 등 서비스 상세 정보에 대해 사실만을  구체적으로 명시해야 하며, 예외적인 범위에 대해서는 의뢰인과 사전 합의해야 합니다.
    8. 이용자는 원칙적으로 서비스 상세 정보에 스스로 제공할 수 있는 서비스의 내용만을 명시해야 하며, 일부 또는 전부를 도급∙위임해야 하는 서비스에 대해서는 회사가 승인하는 범위 내에서 추가로 명시할 수 있습니다. 이 경우, 의뢰인과는 별도의 사전 합의를 진행해야 합니다.
    9. 이용자는 서비스 상세 정보에 직접 결제를 유도하는 문구나 의뢰인의 정당한 권리(청약 철회권 등)를 제한하거나, 기타 법령이나 약관에 위배되는 내용을 명시할 수 없습니다.
    10. 이용자는 의뢰인의 문의에 성실하게 응답해야 합니다.
    11. 이용자는 본 약관 제9조~제11조에 따라 판매의 전 과정을 성실하게 이행해야 합니다.
    12. 이용자는 판매 관련 정보(만족도, 메시지 응답률, 안심번호 응대율, 주문 성공률, 작업일 준수율, 알림·공지사항의 확인 등)를 지속해서 관리하여야 하며, 이를 반영하는 회원 등급 정책에 협조해야 합니다.
    13. 이용자는 판매 불가한 사유가 발생한 경우 회사에서 제공하는 기능을 사용하여 판매를 일시중단하거나 휴가모드를 설정하여야 하며, 거래가 진행 중인 경우에는 의뢰인에게 지체없이 그 사실을 알리고 필요한 조치를 취해야 합니다.
    14. 이용자는 스스로 또는 다른 회원을 통하여 고의로 서비스 평가 등의 판매 데이터를 수정·조작하는 등 서비스의 안전성과 신뢰성을 해치는 부정한 행위를 할 수 없습니다.
    15. 이용자는 계약 이행 과정에서 알게 된 의뢰인 등 다른 회원의 개인 정보를 법률·약관 또는 회사의 개인정보처리방침에서 정한 목적 외의 용도로 사용할 수 없습니다. 회사의 고의 또는 과실과 무관하게 특정 이용자가 처리·처리 중인 다른 회원 또는 제3자의 개인 정보가 침해된 경우, 회사는 그에 대하여 책임을 지지 않으며 이용자가 자신의 노력과 비용으로 회사를 면책시켜야 합니다.
    
    제8조 금지행위 및 이용제한
    1. 회사는 이용자가 본 약관 및 회사의 서비스 운영을 위하여 정하는 정책에 따른 의무를 위반하거나 서비스의 정상적인 운영을 방해하거나 다음 각 호에 해당하는 경우, 서비스 판매를 중지하거나 경고, 사전 통보 후 이용정지 및 영구 이용정지 등으로 서비스 이용을 제한할 수 있고 민형사상의 책임을 물을 수 있습니다.
    1) 이용자에게 서비스 이용약관에서 정하고 있는 가입 제한 사유, 제13조에서 정하고 있는 이용제한 사유가 있음이 확인된 경우
    2) 회사가 제공하는 서비스 이용과 관련하여 이용자가 부담하는 채무를 기일에 지급하지 않는 경우
    3) 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
    4) 서비스를 이용하여 법령 또는 본 약관 에서 금지하거나 공서양속에 반하는 행위를 하는 경우
    5) 회원 간 직접 결제 등 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
    6) 본인 또는 다른 회원의 아이디를 이용하여 내용, 조건 등이 실질적으로 동일한 정보 등을 중복하여 등록·판매 한 경우
    7) 이용자에게 파산·금치산·한정치산·회생·파산의 결정 또는 선고, 사망, 실종선고, 해산, 부도 등 정상적 서비스 이용을 불가능하게 하거나 곤란하게 하는 사유가 발생한 경우
    2. 이용제한 조치 및 이의제기와 관련한 사항은 서비스 이용약관를 준용합니다.
    
    제9조 정보 등의 공급
    1. 회사는 의뢰인이 결제한 이후 메일(e-mail) 또는 문자(SMS) 등의 알림 채널을 통하여 이용자에게 접수된 주문 정보를 통지합니다. 이용자는 회사가 발송하는 알림 및 판매 관리 서비스 화면을 통하여 주문 내역을 확인할 수 있습니다.
    2. 이용자가 정보을 판매하는 경우, 1항에 따른 주문 정보를 통지 받은 후 작업을 진행합니다. 정해진 기간 내에 작업이 불가하거나 상세페이지에 명시한 작업 범위(의뢰인과 별도로 합의한 업무 범위가 있다면 의뢰인과 합의한 작업범위를 포함함)를 변경해야 하는 경우, 이용자는 즉시 의뢰인과 별도 합의를 진행해야 합니다.
    3. 이용자가 콘텐츠를 판매하는 경우, 1항에 따른 주문 접수와 동시에 미리 등록한 콘텐츠가 자동으로 발송됩니다. 이용자는 이를 인지하고 작업물을 항상 최신 상태로 유지해야 하며, 의뢰인에게 추가 안내가 필요한 경우에는 별도의 안내를 진행해야 합니다.
    4. 이용자가 배송이 필요한 재화를 판매하는 경우, 1항에 따른 주문 정보를 통지 받은 후 작업 및 배송을 진행합니다. 정해진 기간 내에 작업이 불가하거나 상세페이지에 명시한 작업 범위(의뢰인과 별도로 합의한 업무 범위가 있다면 의뢰인과 합의한 작업범위를 포함함)를 변경해야 하는 경우에는 즉시 의뢰인과 별도 합의를 진행해야 합니다. 또한, 이용자는 의뢰인이 배송상의 문제로 손해를 입은 경우에는 그에 대한 책임을 부담합니다.
    5. 이용자는 상세페이지에 명시한 작업 범위(의뢰인과 별도로 합의한 업무 범위가 있다면 의뢰인과 합의한 작업범위를 포함함)를 완료하지 않은 상태에서 의뢰인에게 작업물을 발송하거나 구매 확정을 요청해서는 안 됩니다.
    
    제10조 수정 및 거래 중 문제해결
    1. 이용자는 서비스 등록 시 수정·재진행 안내 작성해야 하며, 작업물을 수령한 의뢰인이 7일 이내 [수정 요청] 기능을 이용하여 수정을 요청한 경우 이에 따라 수정 작업을 진행해야 합니다. 수정·재진행은 일부 정보 등의 유형이나 일부 이용자 카테고리에 따라 제공하지 않을 수 있습니다.
    2. 이용자는 상세페이지에 명시한 작업 범위(의뢰인과 별도로 합의한 업무 범위가 있다면 의뢰인과 합의한 작업범위를 포함함)를 모두 완료한 경우 수정·재진행 요청을 거절할 수 있으며, 명시된 무상 수정·재진행 범위를 벗어나는 등 추가적인 작업이 필요한 경우에는 의뢰인과의 합의 하에 추가 결제를 요청할 수 있습니다.
    3. 이용자는 구매 확정된 작업물에 대해 의뢰인이 수정 요청하는 경우 의뢰인과 별도로 합의하여야 합니다.
    �4. 이용자는 작업물 발송 예정일 내에 작업물을 발송할 수 없는 경우 [작업일 연장] 기능을 사용하여 작업일을 연장해야 합니다. 의뢰인은 작업일 연장 요청을 수락 또는 거절할 수 있으며, 의뢰인이 수락한 경우에만 요청한 기간 만큼 작업일이 연장됩니다. 해당 기능은 서비스의 종류와 이용자 카테고리별로 다르게 정해질 수 있습니다.
    5. 이용자의 작업일 준수율, 작업일 연장 기능의 사용 빈도 등에 따라 회사는 이용자에게 기본 작업 기간의 수정을 요청할 수 있습니다.
    6. 이용자는 수정·추가 작업 등에 따라 의뢰인의 추가 결제가 필요한 경우 [추가 결제 요청] 기능을 사용하여 추가 결제를 요청해야 합니다. 의뢰인은 추가 결제 요청을 수락 또는 거절할 수 있으며, 의뢰인이 수락한 경우에만 추가 결제가 완료됩니다. 해당 기능은 서비스의 종류와 이용자 카테고리별로 다르게 정해질 수 있습니다.
    
    제11조 취소 및 환불
    1. 거래 취소는 이용자와 의뢰인 모두가 상대방에게 요청할 수 있으며, 거래 취소 및 환불 여부는 회사가 서비스 상세페이지에서 제공하는 [취소·환불 규정]과 당사자 간 합의에 따라 결정됩니다.
    2. 이용자는 예상치 못한 사유로 정상적인 작업 진행이 어려운 경우 등 정보 등의 제공이 불가한 경우에는 지체없이 의뢰인에게 거래 취소 사유를 안내하고 [거래 취소 요청] 기능을 사용하여 조치를 취하여야 합니다.
    3. 이용자가 의뢰인으로부터 거래 취소(청약철회)를 요청받은 경우, 청약철회 가능 여부를 검토하고 환불에 필요한 조치를 합니다. 다만 이용자는 다음 각 호의 어느 하나에 해당하면 의뢰인의 요청을 거부할 수 있습니다.
    1) 회원 탈퇴한 의뢰인이 환불을 요구할 경우
    2) 기타 정당한 환불 사유로 판명되지 않은 경우
    4. 이용자가 환불을 결정한 시점에 회사로부터 정보 등의 대금을 이미 지급받은 때에는 그 사실을 의뢰인에게 통지하고 환급을 위한 조치를 취하여야 합니다.
    5. 이용자가 의뢰인에게 정보 등을 발송한 경우 의뢰인은 수령일로부터 7일 이내에 직접 구매 확정할 수 있으며, 기한 내에 구매 확정, 수정 요청 등 의뢰인의 의사표시가 접수되지 않는 경우 회사는 자동으로 구매 확정 절차를 진행할 수 있습니다.
    6. 의뢰인이 구매 확정을 하거나 자동 구매 확정 절차가 진행되면 중개서비스가 종료되므로 거래 취소(청약 철회), 환불, 정보 등에 대한 수정과 판매 수수료 및 구매 수수료 반환 등의 절차는 원칙적으로 불가능하며, 이용자와 의뢰인은 회사에 대하여 이의를 제기할 수 없습니다. 이 경우 구매대금 환불 등의 모든 문제는 당사자 간 직접 해결해야 합니다.
    
    제12조 판매 수수료
    1. 회사는 회사가 제공하는 서비스에 대하여 판매 수수료를 부과할 수 있으며, 판매 수수료는 서비스의 종류와 이용자 카테고리별로 다르게 책정될 수 있습니다. 별도의 기준이 있는 경우, 회사는 본 약관 제4조에 규정한 방법 또는 기타의 방법으로 이용자에게 사전 고지합니다.
    2. 진행 중인 주문이 취소되는 경우 판매 수수료는 부과되지 않으며, 의뢰인이 구매 확정을 하거나 자동 구매 확정 절차가 진행되면 서비스가 종료되므로 판매 수수료 반환은 원칙적으로 불가합니다.
    3. 회사는 당월 구매 확정된 주문 건의 판매 수수료에 대하여 익월 초 전자세금계산서를 일괄 발행하며, 본 약관 제4조의 방법으로 이용자에게 통지합니다.
    
    제13조 수익금 및 정산
    1. 회사가 제공하는 결제 서비스로 정보 등을 결제한 의뢰인이 본 약관 제9조에 따라 정보 등을 수령하고 구매 확정하거나 자동 구매 확정이 진행된 경우, 회사는 판매 수수료를 공제한 금액을 이용자의 계정에 수익금으로 지급합니다.
    2. 1항의 절차가 진행되면 중개서비스가 종료되므로 판매 수수료 반환은 원칙적으로 불가하며, 이 경우 구매대금 환불 등의 모든 문제는 이용자와 의뢰인이 직접 해결해야 합니다.
    3. 이용자는 계정에 보유하고 있는 수익금에 대해 출금 신청할 수 있으며, 회사는 이용자의 출금 신청 시 2영업일 이내에 이용자가 지정한 계좌로 수익금을 출금합니다.
    4. 회사는 이용자의 출금 신청 시 이용자의 귀책사유로 인해 발생한 비용 등의 채무가 있는 경우에는 해당 금액을 공제한 후 출금할 수 있습니다.
    5. 이용자는 회사에 대한 정보 등의 대금 채권을 타인에게 양도할 수 없습니다. 다만, 이용자의 채권자가 제기한 신청에 의한 대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을 경우, 회사는 이용자와 채권자 간의 합의 또는 채무액 변제 등으로 위 결정이 해제될 때까지 수익금 출금을 보류하거나 제3채무자로서 정당하게 이용자의 채무를 변제할 수 있습니다.
    6. 회사는 아래 사유가 발생하는 경우 최대 120일까지 수익금의 출금을 중단할 수 있습니다. 이 경우 이용자가 거래 사실 증빙서류 등 관련 서류를 회사에 제공하는 때에는 회사가 이를 확인한 후 출금을 진행할 수 있습니다.
    1) 이용자가 서비스 등록 양식에 어긋난 등록이나 서비스 판매가 목적이 아닌 결제·거래 부정 행위 등의 다른 목적으로 허위 등록, 판매 가장등록, 신용카드 결제 시스템 또는 통신과금 시스템만을 이용하기 위한 등록을 한 경우
    2) 이용자가 매매 부적합한 정보 등을 판매한 것으로 확인되거나 부정 또는 부당한 방법으로 수익금을 취득한 경우, 혹은 이에 준하는 행위가 의심되는 경우
    3) 의뢰인의 불만이 다수 접수되는 등의 사유로 추가적인 환불 요청이 염려되는 경우
    4) 관련 법령의 규정에 의하거나 기타 합리적인 사유가 있는 경우(법원·수사기관·금융기관 등 권한 있는 기관의 요청이 있는 경우, 기타 위탁사를 통해 사실 확인이 필요한 경우 등)
    7. 전항에서 정한 기간을 넘어 수익금의 출금을 중단해야 하는 부득이한 사유가 있는 경우, 회사는 해당 사항을 본 약관 제4조의 방법으로 회원에게 통지하고 유보하거나 상계할 수 있습니다. 
    8. 이용자가 지정한 입금계좌의 예금주가 이용자 인증 정보와 상이한 경우 출금이 불가능하며, 회사는 해당 조건에 맞는 계좌 관련 서류 증빙이 완료될 때까지 수익금의 출금을 보류할 수 있습니다.
    9. 이용자가 판매하고 있는 다수의 정보 등 중 일부에 대하여 본 조에 따른 수익금 출금 보류 사유가 발생한 경우, 회사는 다른 회원의 피해 방지를 위해 필요한 경우 등 합리적인 사유가 있다면 회사는 해당 사유가 직접적으로 발생하지 않은 다른 정보 등의 전부 또는 일부에 대한 정산을 일정 기간 보류할 수 있습니다.
    10. 의뢰인이 신용카드로 결제한 경우, 회사는 정당한 이유가 있다면 여신전문금융업상 규정에 따른 신용카드 부정사용을 통한 허위거래 여부를 판단하기 위해 최고 60일까지 이용자에 대한 정보등의 대금 정산, 수익금 출금을 보류할 수 있습니다. 이 경우, 회사는 거래 사실 확인을 위한 증빙을 이용자에게 요구할 수 있으며, 사실 여부 확인 후 이용자에게 대금 정산, 수익금 출금을 진행할 수 있습니다.
    11. 회사는 수익금 지급과 관련한 수익금 내역, 출금 내역, 세금계산서 등의 정보를 관련 서비스 화면에서 안내합니다.
    12. 회사는 관련 법령 등에 따라 이용자의 분기별 매출 명세를 국세청으로 제출합니다. 이용자는 매출 신고가 누락되지 않도록 관리해야 하며, 회사는 관련 자료의 제공 외의 세무 처리에 대해 개입하거나 책임지지 않습니다.
    13. 현금영수증 발행 의무가 있는 이용자는 의뢰인의 요청사항 또는 국세청 지정 코드에 따라 현금영수증을 발행해야 합니다.
    14. 세금계산서 발행 의무가 있는 이용자는 의뢰인의 요청에 따라 세금계산서를 발행해야 합니다.
    
    제14조 비즈머니의 충전
    1. '충전'이란 이용자가 비즈머니의 일정액을 확보하기 위해 회사가 지정한 지불수단을 통해 충전 비즈머니를 구매하는 것을 말합니다.
    2. 충전 비즈머니는 제1항에서 규정하는 지불수단별로 지정된 금액으로 충전할 수 있으며, 지불수단에 따라 자체 제한 금액이 있을 수 있습니다.
    
    제15조 충전 비즈머니의 이용 및 차감
    1. 충전 비즈머니는 서비스 내에서 유료 광고를 구매할 때 사용할 수 있으며 원화 1원과 같은 가치를 가집니다. 이용자는 회사가 정한 조건에 따라 서비스 이용 과정에서 충전 비즈머니를 단독으로 또는 다른 결제 수단과 함께 사용할 수 있습니다.
    2. 이용자는 회사에서 정한 사용방법에 따라 충전 비즈머니를 사용할 수 있으며, 회사는 구체적인 사항을 관련 서비스 페이지를 통해 안내합니다.
    3. 먼저 충전된 충전 비즈머니가 먼저 사용됩니다.
    4. 이용자가 부당 또는 부정하게 충전 비즈머니를 취득한 경우 이용자는 충전 비즈머니를 사용할 수 없으며 회사는 이를 회수할 수 있습니다.
    5. 이용자가 부정한 목적이나 용도로 충전 비즈머니를 사용하는 경우 회사는 이용자의 충전 비즈머니 사용을 제한하거나 충전 비즈머니를 사용한 구매신청을 취소하거나, 제8조에 따른 제재를 하거나 민형사상 책임을 물을 수 있습니다.
    
    제16조 충전 비즈머니의 소멸
    1. 충전 비즈머니의 유효기간은 5년입니다.
    2. 이용자는 충전 비즈머니를 제17조에 따라 환급받거나 포기하는 데에 동의한 후에 회원 탈퇴가 가능합니다. 포기하는데 동의한 충전 비즈머니는 회원 탈퇴 시 소멸되어 환급, 재지급이 불가능합니다.
    
    제17조 환급 등
    1. 이용자가 아래 각 호의 경우를 포함하여 보유 중인 충전 비즈머니의 환급을 회사에 요구할 경우 회사는 유효 기간 내에서 충전 비즈머니의 잔액 전부를 환급합니다.
    1) 천재지변 등의 사유로 회사에서 유료 광고를 제공하기 곤란하여 충전 비즈머니를 사용하지 못하게 된 경우
    2) 충전 비즈머니의 결함으로 회사에서 유료 광고 등을 제공하지 못하는 경우
    2. 회사는 이른바 '휴대폰 소액결제 깡(현금화)' 등 현금 융통을 위한 불법·부정한 목적의 환급을 제한할 수 있습니다.
    3. 이용자는 회사에서 정한 기한 내에 충전 비즈머니를 사용하거나 환급 받을 수 있으며, 회사는 구체적인 사항을 관련 서비스 페이지를 통해 안내합니다.
    
    제18조 충전 비즈머니의 양도금지
    이용자는 충전 비즈머니에 대한 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
    
    제19조 적립 비즈머니
    1. 적립 비즈머니는 회사에서 무상으로 적립하는 포인트이며, 회사는 이용자의 서비스 판매 완료 및 이벤트 참여 등 일정한 서비스 이용을 조건으로 이용자에게 적립 비즈머니를 지급할 수 있습니다. 구체적인 적립 내용 및 조건은 회사의 정책에 따라 달라질 수 있습니다.
    2. 적립 비즈머니는 서비스 내에서 유료 광고를 구매할 때 사용할 수 있으며 원화 1원과 같은 가치를 가집니다. 이용자는 회사가 정한 조건에 따라 서비스 이용 과정에서 적립 비즈머니를 단독으로 또는 다른 결제 수단과 함께 사용할 수 있습니다.
    3. 이용자가 적립 비즈머니 사용처에서 유료 광고를 구매할 경우, 회사는 재화 등의 특성이나 적립 비즈머니 사용처에 관한 정책상 지급수단을 제한할 수 있습니다.
    4. 적립 비즈머니의 부여 및 사용에 관한 상세한 사항은 회사가 정한 정책에 따르며, 회사는 관련 서비스 페이지 등을 통하여 이를 회원에게 안내합니다. 이는 모든 이용자에게 동일하게 적용됩니다.
    5. 무상으로 적립된 적립 비즈머니는 현금으로 전환하거나 출금할 수 없습니다.
    6. 다음 각 호에 해당하는 경우 적립 비즈머니가 소멸되며, 어떠한 경우에도 복구되지 않습니다.
    1) 적립 비즈머니의 유효기간이 경과하는 경우
    2) 서비스의 이용계약이 종료(회원탈퇴, 해지 포함) 되는 경우
    7. 이용자는 적립 비즈머니를 본인의 거래에 대해서만 사용할 수 있으며, 어떠한 경우라도 타인에게 매매 또는 양도하거나, 실질적으로 매매 또는 양도와 동일하게 볼 수 있는 행위를 하여서는 아니 됩니다.
    8. 이용자가 부당 또는 부정하게 적립 비즈머니를 취득한 경우 이용자는 적립 비즈머니를 사용할 수 없으며 회사는 이를 회수할 수 있습니다.
    9. 이용자가 부정한 목적이나 용도로 적립 비즈머니를 사용하는 경우 회사는 이용자의 적립 비즈머니 사용을 제한하거나 적립 비즈머니를 사용한 구매신청을 취소하거나 제8조에 따른 제재를 하거나 민형사상 책임을 물을 수 있습니다.
    10. 적립 비즈머니는 해당 서비스 판매가 취소되는 경우 회사에 의해 환수될 수 있습니다. 적립 비즈머니 잔여분이 있는 경우 자동으로 차감될 수 있고, 잔여분이 없는 경우 적립될 때마다 즉시 환수대상 적립 비즈머니 총액이 충족될 때까지 자동으로 차감될 수 있습니다.
    11. 이용자가 회사로부터 무상으로 제공받은 적립 비즈머니는 회사가 정하여 고지한 유효기간 내에만 이용이 가능합니다.
    12. 적립 비즈머니의 유효기간은 회사가 정한 정책에 따르며, 관련 서비스 페이지를 통해 고지합니다. 회사가 서비스/이벤트에 따라 별도로 정하여 본 약관 제4조의 방법으로 고지한 유효기간이 있는 경우에는 그 유효기간에 따릅니다.
    13. 회사는 적립 비즈머니의 소멸 예정사실을 1회 이상 이용자에게 이메일 등으로 통지하며 유효기간 연장은 불가합니다. 
    14. 먼저 적립된 비즈머니가 먼저 사용/소멸됩니다.
    
    제20조 계정의 관리
    1. 회사는 회원으로부터 계정(아이디, 비밀번호)의 분실 또는 도난 등의 통지를 받기 전에 발생하는 적립/충전 비즈머니로 저장된 금액에 대한 손해에 대해서는 책임지지 않습니다.
    2. 회사는 전자지급결제대행 서비스 제공 시 계정(아이디, 비밀번호)를 선정하여 회원의 신원, 권한 및 거래지시의 내용 등을 확인합니다.
    3. 회원은 계정(아이디, 비밀번호)를 사용함에 있어서 다른 법률에 특별한 규정이 없는 한 다음 각 호의 행위를 하여서는 아니 됩니다.
    1) 계정(아이디, 비밀번호)을 양도하거나 양수하는 행위
    2) 계정(아이디, 비밀번호)을 대여하거나 사용을 위임하는 행위
    3) 계정(아이디, 비밀번호)을 질권 및 기타 담보 목적으로 하는 행위
    4) 1호부터 3호까지의 행위를 알선하는 행위
    4. 회원은 자신의 계정(아이디, 비밀번호)를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 계정(아이디, 비밀번호)의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
    5. 회사는 회원으로부터 계정(아이디, 비밀번호)의 분실이나 도난 등의 통지를 받은 때에는 그때부터 제3자가 그 계정(아이디, 비밀번호)를 사용함으로 인하여 회원에게 발생한 손해를 배상할 책임이 있습니다.
    
    제21조 유료 광고
    1. 이용자는 정보 등에 유료 광고를 구매·적용할 수 있습니다. 회사는 유료 광고 제공에 대한 내용을 당해 서비스가 제공되는 개별 페이지에서 유의사항의 형태로 안내합니다.
    2. 회사는 유료 광고의 종류와 내용, 이용료를 신설 또는 변경할 수 있으며 이 경우 본 약관 제4조에 규정한 방법으로 알려드리겠습니다.
    
    제3장 기타
    제22조 책임제한
    회사는 이용자에게 본 약관에 명시되지 않는 사항에 대한 약정이나 보증을 하지 않습니다. 단, 회사 및 회사의 임직원, 대리인의 고의 또는 중대한 과실이 인정되는 경우에는 그러하지 아니합니다. 
    
    제23조 손해배상
    1. 회사는 회사의 고의, 과실로 인하여 이용자가 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 이용자의 손해를 배상하겠습니다. 다만 회사는 회사의 고의, 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
    1) 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
    2) 이용자의 귀책사유로 서비스 이용에 장애가 발생한 경우
    3) 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해
    4) 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해
    5) 제3자의 회사 서버에 대한 전송 또는 회사서버로부터의 전송을 방해함으로써 발생하는 손해
    6) 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
    7) 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해
    8) 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
    2. 회사는 회사의 고의, 과실이 없는 한 이용자 상호간 또는 이용자와 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
    3. 회사는 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 정보를 이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다. 
    4. 이용자가 본 약관의 규정 또는 관련 법령을 위반하여 회사에 손해가 발생한 경우 회사는 이용자에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
    5. 회사가 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 관련 책임이 면제됩니다. 
    6. 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대해서는 책임을 지지 않습니다.
    
    제24조 분쟁해결
    1. 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 해결하기 위하여 고객센터를 설치·��영합니다.
    2. 회사는 이용자로부터 제출되는 불만사항 및 의견을 우선적으로 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
    3. 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정 등에 따를 수 있습니다.
    
    제25조 준거법 및 재판권
    1. 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다. 
    2. 회사는 이용자와의 분쟁이 발생할 경우 이의 해결을 위해 성실히 협의할 것이나, 그럼에도 불구하고 분쟁이 해소되지 않으면 이에 관한 소송은 민사소송법상의 관할법원에 제기할 수 있습니다.
    
    `;
  return <div className="mx-auto my-10 w-4/6 whitespace-pre-line">{text}</div>;
}
