/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { FaPlus, FaMinus, FaPen } from "react-icons/fa6";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

interface ApiResponse<T = any> {
  code: number;
  msg: string;
  token?: string;
  data?: T;
  posts: any[];
}

interface MarketProps {
  type: "구매" | "판매" | "정정" | "체결";
  bodyData?: any;
}

const formatAmount = (amount: number | undefined): string => {
  if (amount === undefined) return "-";
  return amount.toLocaleString("en-US") + " 원";
};

const MarketmainDetail: React.FC<MarketProps> = React.memo(
  ({ type, bodyData = {} }) => {
    const [postID, setPostID] = useState<number>(bodyData?.post?.id || 0);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editID, setEditID] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [buyPrice, setBuyPrice] = useState<number>(
      bodyData?.post?.now_count || 0
    );
    const [quantity, setQuantity] = useState<number>(1);
    const [correctList, setCorrectList] = useState<any[]>([]);
    const [completeList, setCompleteList] = useState<any[]>([]);

    const handleQuantityChange = useCallback(
      (amount: number, type: "구매" | "판매" | "정정" | "체결") => {
        if (type === "판매") {
          const maxQuantity =
            (bodyData?.my_list?.[1] || 0) - (bodyData?.my_list?.[3] || 0);
          setQuantity((prevQuantity) =>
            Math.max(1, Math.min(prevQuantity + amount, maxQuantity))
          );
        } else if (type === "구매") {
          setQuantity((prevQuantity) =>
            Math.max(
              1,
              Math.min(prevQuantity + amount, bodyData?.post?.ticket_num)
            )
          );
        } else if (type === "정정") {
          // 정정일 때는 수량을 변경하지 않음
          return;
        } else {
          setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
        }
      },
      [bodyData]
    );

    const handlePriceChange = useCallback(
      (amount: number) => {
        if (bodyData?.post?.remain_ticket !== 0) {
          setBuyPrice(bodyData.post.want_count); // 가격을 want_count로 고정
        } else {
          setBuyPrice((prevPrice) => Math.max(1, prevPrice + amount)); // 가격을 변경
        }
      },
      [bodyData]
    );

    useEffect(() => {
      setPostID(bodyData?.post?.id || 0);
      setBuyPrice(bodyData?.post?.now_count || 0);

      const fetchDataList = async (
        url: string,
        setData: React.Dispatch<React.SetStateAction<any[]>>
      ) => {
        try {
          const apiService = new ApiService(
            process.env.REACT_APP_POTEN_URL || ""
          );
          const formData = new FormData();
          formData.append("post_id", postID.toString());

          const response = await apiService.post<ApiResponse>(
            url,
            formData,
            Cookies.get("token") || undefined
          );

          if (response.data.code === 200) {
            setData(response.data.posts || []);
            console.log(response.data);
          } else if (response.data.code === 300) {
            if (
              window.confirm(
                "로그인 후 이용 가능합니다. 로그인 페이지로 이동하시겠습니까?"
              )
            ) {
              window.location.href = "/login";
            }
          } else {
            console.error("데이터 가져오기 오류:", response.data.msg);
          }
        } catch (error) {
          console.error("정보 업데이트 중 오류 발생:", error);
        }
      };

      if (postID !== 0) {
        fetchDataList("/posts/transaction_correct_info/", setCorrectList);
        fetchDataList("/posts/transaction_complete_list/", setCompleteList);
      }
    }, [bodyData, postID]);

    const fetchAndSetData = useCallback(
      async (url: string, editID?: number) => {
        try {
          const apiService = new ApiService(
            process.env.REACT_APP_POTEN_URL || ""
          );
          const formData = new FormData();

          // 요청 전 값들을 로그로 출력하여 확인
          console.log("postID:", postID);
          console.log("buyPrice:", buyPrice);
          console.log("quantity:", quantity);

          formData.append("post_id", postID.toString());
          formData.append("amount", buyPrice.toString());
          formData.append("count", quantity.toString());

          if (type === "정정" && editID) {
            console.log("Sending editID:", editID);
            formData.append("transaction_id", editID.toString());
          }

          const response = await apiService.post<ApiResponse>(
            url,
            formData,
            Cookies.get("token") || undefined
          );

          if (response.data.code === 200) {
            alert(response.data.msg);
            window.location.reload();
          } else if (response.data.code === 201) {
            alert(response.data.msg);
          } else if (response.data.code === 301 && type === "구매") {
            if (window.confirm("포인트 부족. 포인트를 구매해주세요")) {
              window.location.href = "/mypagePoint";
            }
          } else {
            console.error("데이터 가져오기 오류:", response.data);
          }
        } catch (error) {
          // 오류가 발생했을 때 상세 로그 출력
          console.error("정보 업데이트 중 오류 발생:", error);

          // 사용자에게 에러 메시지 표시
          alert(
            "서버와의 통신 중 문제가 발생했습니다. 나중에 다시 시도해 주세요."
          );
        }
      },
      [buyPrice, postID, quantity, type]
    );

    const handleButtonClick = useCallback(async () => {
      switch (type) {
        case "구매":
          await fetchAndSetData("/posts/transaction_purchase/");
          break;
        case "판매":
          await fetchAndSetData("/posts/transaction_sel/");
          break;
        case "정정":
          if (isEditing) {
            if (!editID) {
              console.error("Edit ID가 설정되지 않았습니다.");
              return;
            }
            await fetchAndSetData("/posts/transaction_correct/", editID);
          } else {
            setIsEditing(true);
          }
          break;
        case "체결":
          await fetchAndSetData("/posts/transaction_complete_list/");
          break;
      }
    }, [fetchAndSetData, type, isEditing, editID]);

    const handleCancelOrders = useCallback(async () => {
      try {
        const apiService = new ApiService(
          process.env.REACT_APP_POTEN_URL || ""
        );

        if (selectedItems.length > 0) {
          const formData = new FormData();
          formData.append("post_id", postID.toString());
          formData.append("transaction_list", selectedItems.toString());

          const response = await apiService.post<ApiResponse>(
            "/posts/delete_transaction/",
            formData,
            Cookies.get("token") || undefined
          );

          if (response.data.code === 200) {
            alert("주문이 성공적으로 취소되었습니다.");
            window.location.reload();
          } else {
            console.error("주문 취소 중 오류 발생:", response.data.msg);
          }
        }

        setSelectedItems([]);
        setAllSelected(false);
      } catch (error) {
        console.error("주문 취소 중 오류 발생:", error);
      }
    }, [selectedItems, fetchAndSetData]);

    const handleSelectItem = useCallback((id: number) => {
      setSelectedItems((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      );
    }, []);

    const handleSelectAll = useCallback(() => {
      if (allSelected) {
        setSelectedItems([]);
      } else {
        setSelectedItems(correctList.map((item: any) => item.id));
      }
      setAllSelected(!allSelected);
    }, [allSelected, correctList]);

    const renderSharedSection = useCallback(
      (
        label: string,
        priceLabel: string,
        buttonLabel: string,
        colorClass: string
      ) => (
        <>
          <div className="flex justify-between mb-2">
            {buttonLabel === "구매하기" ? (
              <>
                <span>{label}</span>
                <span>{bodyData?.my_point}원</span>
              </>
            ) : null}
          </div>
          <div className="flex justify-between items-center p-2 mb-2 rounded-lg bg-gray-200">
            <span>{priceLabel}(1주)</span>
            <div className="flex items-center">
              <span className="mx-2">{formatAmount(buyPrice)}</span>
              <button
                className="bg-gray-50 rounded-l-md px-1 py-1"
                onClick={() => handlePriceChange(-100)}
              >
                <FaMinus />
              </button>
              <button
                className="bg-gray-50 rounded-r-md mx-0.5 px-1 py-1"
                onClick={() => handlePriceChange(100)}
              >
                <FaPlus />
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center p-2 mb-2 rounded-lg bg-gray-200">
            <span>수량(주)</span>
            <div className="flex items-center">
              <span className="mx-2">{quantity}</span>
              <button
                className="bg-gray-50 rounded-l-md px-1 py-1"
                onClick={() => handleQuantityChange(-1, type)}
              >
                <FaMinus />
              </button>
              <button
                className="bg-gray-50 rounded-r-md mx-0.5 px-1 py-1"
                onClick={() => handleQuantityChange(1, type)}
              >
                <FaPlus />
              </button>
            </div>
          </div>
          <button
            className={`${colorClass} text-white text-lg font-semibold w-full py-2 rounded-lg`}
            onClick={handleButtonClick}
          >
            {buttonLabel}
          </button>
        </>
      ),
      [
        buyPrice,
        quantity,
        handleButtonClick,
        handlePriceChange,
        handleQuantityChange,
        bodyData,
      ]
    );

    const renderCorrectionTable = useCallback(() => {
      return (
        <div className="w-full overflow-x-auto">
          {correctList.length > 0 ? (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    구분
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    가격
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    수량
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    <button
                      className="ml-1 px-1 py-0.5 font-semibold text-gray-50 bg-green-400 rounded-xl hover:opacity-70 cursor-pointer"
                      onClick={handleCancelOrders}
                      disabled={selectedItems.length === 0}
                    >
                      주문취소
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {correctList.map((item: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleSelectItem(item.id)}
                      />
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {item.is_sell === 0 ? (
                        <span className="text-red-500">구매</span>
                      ) : (
                        <span className="text-blue-500">판매</span>
                      )}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {formatAmount(item.amount)}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {item.quantity}
                    </td>
                    <td className="px-6 py-4 justify-center items-center border-b border-gray-300 text-sm xl:text-xs">
                      <button
                        className=" text-gray-500 hover:text-gray-700"
                        onClick={() => {
                          setBuyPrice(item.amount);
                          setQuantity(item.quantity);
                          setIsEditing(true);
                          setEditID(item.id);
                        }}
                      >
                        <FaPen />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="min-h-48 flex flex-row justify-center items-center bg-green-100">
              <p className="text-green-500 font-semibold">
                미체결 목록이 없습니다.
              </p>
            </div>
          )}
        </div>
      );
    }, [
      allSelected,
      handleSelectAll,
      handleCancelOrders,
      selectedItems,
      correctList,
      handleSelectItem,
    ]);

    const renderSettlementTable = useCallback(() => {
      return (
        <div className="w-full overflow-x-auto">
          {completeList.length > 0 ? (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    가격
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    수량
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    체결금액
                  </th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider xl:text-xs">
                    체결일
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {completeList.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {formatAmount(item.amount)}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {item.quantity}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {formatAmount(item.amount * item.quantity)}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm xl:text-xs">
                      {item.created_at
                        ? new Intl.DateTimeFormat("ko-KR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(new Date(item.created_at))
                        : "Invalid date"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="min-h-48 flex flex-row justify-center items-center bg-gray-100">
              <p className="font-semibold">체결 내역이 없습니다.</p>
            </div>
          )}
        </div>
      );
    }, [completeList]);

    const renderBody = useCallback(() => {
      const sectionLabels: { [key in MarketProps["type"]]: string } = {
        구매: "주문가능금액",
        판매: "판매가능금액",
        정정: "정정가능금액",
        체결: "체결가능금액",
      };

      const priceLabel: { [key in MarketProps["type"]]: string } = {
        구매: "구매가격",
        판매: "판매가격",
        정정: "정정가격",
        체결: "체결가격",
      };

      const buttonLabels: { [key in MarketProps["type"]]: string } = {
        구매: "구매하기",
        판매: "판매하기",
        정정: isEditing ? "정정하기" : "수정",
        체결: "",
      };

      const colorClass =
        type === "구매"
          ? "bg-red-500"
          : type === "판매"
          ? "bg-blue-500"
          : "bg-green-500";

      if (type === "판매" && bodyData?.post?.remain_ticket !== 0) {
        return (
          <div className="w-full overflow-x-auto">
            <div className="min-h-48 flex flex-row justify-center items-center bg-blue-100">
              <p className="text-blue-500 font-semibold">
                출시된 판매할 수량이 남아있습니다. :{" "}
                {bodyData?.post?.remain_ticket} 개
              </p>
            </div>
          </div>
        );
      } else if (
        (type === "판매" && bodyData?.my_list?.[1] === 0) ||
        (type === "판매" &&
          bodyData?.my_list?.[1] - (bodyData?.my_list?.[3] || 0) < 1)
      ) {
        return (
          <div className="w-full overflow-x-auto">
            <div className="min-h-48 flex flex-row justify-center items-center bg-blue-100">
              <p className="text-blue-500 font-semibold">
                판매할 수량이 없습니다.
              </p>
            </div>
          </div>
        );
      }

      if (type === "정정" && !isEditing) {
        return renderCorrectionTable();
      }

      if (type === "체결") {
        return renderSettlementTable();
      }

      return (
        <div className="flex flex-col my-6 text-lg font-semibold">
          {renderSharedSection(
            sectionLabels[type],
            priceLabel[type],
            buttonLabels[type],
            colorClass
          )}
        </div>
      );
    }, [
      type,
      isEditing,
      bodyData,
      renderSharedSection,
      renderCorrectionTable,
      renderSettlementTable,
    ]);

    return (
      <div className="mx-auto min-h-48 w-full max-w-2xl lg:mx-0 lg:max-w-none">
        {renderBody()}
      </div>
    );
  }
);

export default MarketmainDetail;
