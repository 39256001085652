import React from "react";

interface IconProps {
  name: "check"; // 확장 가능: 다른 아이콘을 추가하려면 여기서 추가 가능
  color?: string;
  fill?: string;
  width?: string;
  height?: string;
  margin?: string;
  transform?: string;
  animation?: string;
  fontSize?: string;
}

const Icon: React.FC<IconProps> = (props) => {
  const defaultProps = {
    margin: "0px",
  };

  const styles: React.CSSProperties = {
    color: props.color ?? "#030303", // color 값을 props로 받음
    fill: props.fill ?? "#030303",   // fill 값을 props로 받음
    fontSize: props.fontSize ?? "40px", // fontSize도 props로 받을 수 있도록 수정
    width: props.width ?? "40px",
    height: props.height ?? "40px",
    margin: props.margin ?? defaultProps.margin,
    cursor: "pointer",
    transform: props.transform ?? undefined, // null 대신 undefined 사용
    animation: props.animation ?? undefined, // null 대신 undefined 사용
  };

  const Check = () => (
    <svg style={styles} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none"></path>
      <path
        d="M12 2c3.86 0 7 3.14 7 7 0 5.25-7 13-7 13S5 14.25 5 9c0-3.86 3.14-7 7-7zm-1.53 12L17 7.41 15.6 6l-5.13 5.18L8.4 9.09 7 10.5l3.47 3.5z"
        fill={props.fill ?? "#030303"} // SVG 내부의 색상 적용
      ></path>
    </svg>
  );

  return <>{props.name === "check" && <Check />}</>;
};

export default Icon;
