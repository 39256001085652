import React from "react";

export default function Privacy() {
  const text = `㈜쉐릿 개인정보 처리방침

    쉐릿(이하 ‘회사’라 합니다)는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
    
    제1조 (개인정보의 처리 목적)
    
    회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
    
    1) 홈페이지(에플리케이션 포함, 이하 ‘홈페이지’라 합니다) 회원가입 및 관리
    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 및 인증, 회원자격 유지 및 관리, 제한적 본인확인제 시행에 따른 본인 확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지 및 통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
    
    2) 서비스 제공에 대한 계약이행 및 유료서비스에 대한 결제
    서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 본인인증, 요금결제, 정산, 채권 추심 등을 목적으로 개인정보를 처리합니다.
    
    3) 마케팅, 광고 및 신규 서비스 개발에의 활용
    신규서비스 개발 및 맟춤 서비스 제공, 이벤트, 광고성 정보 및 참여 기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게제, 서비스 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
    
    제2조 (수집하는 개인정보 항목)
    
    1) 회사는 회원 가입에 따른 원활한 고객응대 및 서비스 제공을 위해 아래와 같은 최소한의 개인정보를 수집하고 있습니다.
    1. 필수항목: 성명, 생년월일, 휴대폰 번호
    2. 선택항목: 프로필 사진
    
    2) 서비스의 이용과정이나 사업적 업무처리 과정에서 아래와 같은 정보들이 추가로 수집될 수 있습니다.
    1. 거래정보: 환불을 위한 은행계좌정보, 신용카드 결제정보(카드번호, 유효기간, 비밀번호 앞 두자리), 세금계산서 발행을 위한 회계 담당자 정보(이름, 연락처, 이메일 주소 등)
    2. 서비스 이용정보: 휴대폰 번호, IP 주소, 쿠키, 접속로그, 서비스 이용내역, 불량이용기록, 기기정보, 브라우저 정보, 운영체제 정보(OS), Mac 주소, 결제기록, 생년월일, 성별, 사용중인 통신사 정보 등
    
    제3조 (개인정보 수집 방법)
    
    회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.
    1) 이용자가 직접 홈페이지에 개인정보를 입력하는 형태로 수집
    2) 서면양식, 전화, 이메일, 이벤트 응모, 팩스, 상담 툴, 고객센터 게시판 등에 이용자가 직접 입력하는 형태로 수집
    3) 협력회사로부터의 제공
    4) 생성정보 수집 툴을 통한 수집
    
    제4조 (개인정보의 제3자 제공)
    
    회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
    
    제5조 (개인정보처리 위탁)
    
    1) 회사는 서비스 향상과 원활한 개인정보 업무처리를 위해 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
    1. 신용카드를 통한 결제 대행 및 휴대폰 본인 확인
    2. 통큰아이: 국내 클라우드 서버 운영 및 관리
    
    2) 회사는 위탁계약 체결시 ‘개인정보보호법’ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 보호조치, 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
    
    3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
    
    제6조 (개인정보 처리 및 보유기간)
    
    1) 홈페이지 회원 가입 및 관리: 회사 홈페이지 탈퇴 시까지, 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지1. 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지
    2. 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
    
    2) 서비스 제공: 서비스 공급완료 및 요금결제·정산 완료시까지, 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
    1. 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록가. 표시·광고에 관한 기록: 6개월
    나. 계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년 
    다. 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
    2. 「통신비밀보호법」에 따른 통신사실확인자료 보관가. 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
    나. 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
    
    제7조 (정보주체와 법정대리인의 권리, 의무 및 그 행사방법)
    
    1) 정보주체는 회사에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.
    
    2) 제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 할 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.
    
    3) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있습니다. 이 경우 “개인정보 처리 방법에 대한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
    
    4) 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
    
    5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
    
    6) 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
    
    제8조 (개인정보의 파기)
    
    1) 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
    
    2) 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
    
    3) 개인정보 파기의 절차 및 방법은 다음과 같습니다.
    1. 파기절차
    파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보보호책임자의 승인을 받아 개인정보를 파기합니다.
    2. 파기방법
    가. 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
    나. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
    
    4) 휴면계정의 개인정보 분리보관
    회사는 회원가입 후 1년동안 서비스를 이용하지 않은 이용자를 휴면계정으로 전환하고, 개인정보를 별도로 분리 보관합니다.
    1. 관련법령: 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제29조 제2항
    2. 회사는 휴면계정 이용자에게 휴면계정 전환 20일 전까지 아래의 내용을 통지하여야 합니다.
    가. 개인정보 분리 보관 사실
    나. 휴면계정 전환 예정일
    다. 분리되는 개인정보 항목
    3. 휴면 계정으로 전환된 이용자는 휴면계정을 해제하기 전까지 서비스 이용이 제한됩니다.
    4. 로그인을 통해 휴면계정을 해제하면, 서비스 이용을 제개할 수 있습니다.
    
    제9조 (개인정보의 안전성 확보 조치)
    
    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
    
    1) 개인정보 취급 직원의 최소화 및 교육
    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
    
    2) 내부관리계획의 수립 및 시행
    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하여 시행하고 있습니다.
    
    3) 해킹 등에 대비한 기술적 대책
    해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 및 점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적·물리적으로 감시 및 차단하고 있습니다.
    
    4) 개인정보의 암호화
    
    이용자의 개인정보 중 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알 수 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.
    
    5) 접속기록의 보관 및 위변조 방지
    
    개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관 및 관리하고 있으며, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나 고유식별정보 또는 민감정보를 처리하는 경우에는 2년 이상 보관 및 관리하고 있습니다. 또한, 접속기록이 위변조, 도난 및 분실되지 않도록 보안기능을 사용하고 있습니다. 
    
    6) 개인정보에 대한 접근 제한
    
    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며, 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
    
    7) 비인가자에 대한 출입 통제
    
    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대한 출입통제 절차를 수립 및 운영하고 있습니다.
    
    제10조 (개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항)
    
    1) 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
    
    2) 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며, 이용자들의 PC 내의 하드디스크에 저장되기도 합니다.
    
    3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
    
    제11조 (개인정보관리책임자 및 담당자의 연락처)
    
    1) 성명: 김세영
    
    2) 연락처: keynes30@naver.com
    
    제12조 (권익침해 구제방법)
    
    이용자는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
    1) 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr)
    2) 개인정보침해신고센터: (국번없이) 118 (privacy,kisa.or.kr)
    3) 대검찰청: (국번없이) 1301 (www.spo.go.kr)
    4) 경찰청: (국번없이) 182 (cyberbureau.police.go.kr)
    
    제13조 (책임의 범위)
    
    서비스에 링크되어 있는 다른 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 ‘개인정보 처리방침’이 적용되지 않습니다. 또한 본 ‘개인정보 처리방침’은 회사와 이용계약을 체결한 회원에 한해 적용됩니다.
    
    제14조 (고지의 의무)
    
    본 ‘개인정보 처리방침’의 내용 추가, 삭제 및 수정이 있을 시 개정 전 최소 7일 전부터 카카오톡을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지할 것입니다.
    
    제15조 (개인정보 처리방침 변경)
    
    본 ‘개인정보 처리방침’은 2024년  02월  18일부터 적용됩니다.
    
    `;
  return (
    <div className="mx-auto my-10 w-4/6 whitespace-pre-line">
      <p>{text}</p>
    </div>
  );
}
