// 채널 정보 신청 전
import React, { useState } from "react";
import FormText from "../components/FormText.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  user: any;
  profile: any;
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const QandAForm: React.FC = () => {
  const [infoData, setInfoData] = useState({
    name: "",
    email: "",
    title: "",
    contents: "",
  });

  const handleDataChange = (newData: string | File, id: string) => {
    if (infoData[id] !== newData) {
      setInfoData((prev) => ({ ...prev, [id]: newData }));
    }
  };

  const handleUpdateInfo = async (): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("name", infoData.name);
      formData.append("email", infoData.email);
      formData.append("title", infoData.title);
      formData.append("contents", infoData.contents);

      const token = Cookies.get("token") || "";

      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.post<ApiResponse>(
        "/posts/contact_us/",
        formData,
        token
      );

      handleUpdateInfoResponse(response.data);
    } catch (error) {
      console.error("정보 업데이트 중 오류 발생:", error);
    }
  };

  const handleUpdateInfoResponse = (responseData: ApiResponse) => {
    if (responseData.code === 200) {
      alert(responseData.msg);
      console.log(responseData);
    } else if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-16 m-20 lg:1/2">
      <h1 className="self-center text-3xl font-bold">문의 하기</h1>
      <section className="grid grid-cols-1 py-8 gap-4">
        <FormText
          type={"input"}
          id={"name"}
          edit={true}
          title={"성함"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"email"}
          edit={true}
          title={"이메일"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"title"}
          edit={true}
          title={"제목"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"area"}
          id={"contents"}
          edit={true}
          title={"내용"}
          onDataChange={handleDataChange}
        />
      </section>
      <button
        className="w-full p-2 text-base text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90"
        onClick={handleUpdateInfo}
      >
        문의 하기
      </button>
    </div>
  );
};

export default QandAForm;
