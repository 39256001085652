import React from "react";
import Icon from "../components/Icon.tsx";

const About: React.FC = () => {
  return (
    <div className="grid grid-rows-3 grid-cols-3">
      <section className="row-span-3 col-span-3 bg-[#161b39] gap-x-12 py-10">
        <div className="mx-auto w-4/5 flex flex-row justify-between items-center">
          <div className="w-3/5 flex flex-col justify-center items-start gap-y-12 min-h-[33vh] lg:w-2/5">
            <h1 className="font-bold text-4xl text-gray-50 lg:text-5xl">
              포텐이란
            </h1>
            <p className="whitespace-pre-wrap break-all text-base text-gray-50 lg:text-2xl">
              {`유튜버의 가능성을 보고 투자하는 자유로운 거래장으로, 수량 하나 당 하나의 저작권 수입이 가능합니다.`}
            </p>
          </div>
          <div className="w-2/5 ">
            <img
              src="https://images.unsplash.com/photo-1611162616475-46b635cb6868?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw3fHx5b3V0dWJlfGVufDF8fHx8MTcyOTA2MzM4OXww&ixlib=rb-4.0.3&q=80&w=1080"
              alt=""
              style={{
                width: "100%", // 부모 요소의 너비에 맞게 조정
                height: "auto", // 높이를 자동으로 조정하여 비율 유지
                maxWidth: "538px", // 최대 너비를 지정
                maxHeight: "307px", // 최대 높이를 지정
                borderRadius: "8px",
                objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채움
              }}
            />
          </div>
        </div>
      </section>
      <section className="row-span-1 col-span-3 mx-auto py-10 w-11/12 lg:w-4/5">
        <div className="flex flex-col justify-center items-start gap-y-12 min-h-[33vh]">
          <h1 className="text-center font-bold text-2xl lg:text-5xl">
            포텐 거래 방법
          </h1>
          <div className="w-full px-8">
            <img
              src="https://poten.me/static/about_card1.png"
              alt=""
              style={{
                marginLeft:'auto',
                width: "100%", // 부모 요소의 너비에 맞게 조정
                height: "auto", // 높이를 자동으로 조정하여 비율 유지
                borderRadius: "8px",
                objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채움
              }}
            />
          </div>
          <div className="w-full px-8">
            <img
              src="https://poten.me/static/about_card2.png"
              alt=""
              style={{
                marginLeft:'auto',
                width: "100%", // 부모 요소의 너비에 맞게 조정
                height: "auto", // 높이를 자동으로 조정하여 비율 유지
                borderRadius: "8px",
                objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채움
              }}
            />
          </div>
          <div className="w-full px-8">
            <img
              src="https://poten.me/static/about_card3.png"
              alt=""
              style={{
                marginLeft:'auto',
                width: "100%", // 부모 요소의 너비에 맞게 조정
                height: "auto", // 높이를 자동으로 조정하여 비율 유지
                borderRadius: "8px",
                objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채움
              }}
            />
          </div>
          <div className="w-full px-8">
            <img
              src="https://poten.me/static/about_card4.png"
              alt=""
              style={{
                marginLeft:'auto',
                width: "100%", // 부모 요소의 너비에 맞게 조정
                height: "auto", // 높이를 자동으로 조정하여 비율 유지
                borderRadius: "8px",
                objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채움
              }}
            />
          </div>
        </div>
      </section>
      <section className="row-span-1 col-span-3">
        <div className="py-20 flex flex-col justify-center items-start gap-y-5 min-h-[33vh] bg-[#f0f0f0]">
          <div className="mx-auto w-11/12 flex flex-col gap-y-4 lg:w-4/5">
            <h1 className="font-bold text-lg lg:text-3xl">포텐 수수료</h1>
            <div className="pl-3 flex flex-row justify-start items-center">
              <Icon
                name="check"
                color="#FF0000"
                fill="#FF0000"
                width="40px"
                height="40px"
              />
              <p>
                포텐에서는 원활한 거래 및 플랫폼 서비스 향상을 위해 거래 체결 시
                1주당 1.0%의 거래 수수료를 부과합니다.
              </p>
            </div>
            <div className="pl-3 flex flex-row justify-start items-center">
              <Icon
                name="check"
                color="#FF0000"
                fill="#FF0000"
                width="40px"
                height="40px"
              />
              <p>
                수수료는 5주 미만일 경우 1.0%, 5주 이상일 경우 0.8%로 감면하여
                부과됩니다.
              </p>
            </div>
            <div className="pl-3 flex flex-row justify-start items-center">
              <Icon
                name="check"
                color="#FF0000"
                fill="#FF0000"
                width="40px"
                height="40px"
              />
              <p>수수료는 "현재" 체결 완료된 거래에만 적용됩니다.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
