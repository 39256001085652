import React, { useState, useEffect, useCallback } from "react";
import FormText from "../components/FormText.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

interface ApiResponse<T = any> {
  user: User;
  profile: Profile;
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

interface User {
  email: string;
  phone: string;
}

interface Profile {
  name: string;
  bank: string;
  bank_num: string;
}

const InfoModify: React.FC = () => {
  const [infoData, setInfoData] = useState({
    name: "",
    email: "",
    phone: "",
    bank: "카카오",
    bank_num: "",
  });

  const handleDataChange = useCallback((newData: string | File, id: string) => {
    setInfoData((prev) => {
      if (prev[id] !== newData) {
        return { ...prev, [id]: newData };
      }
      return prev;
    });
  }, []);

  const handleOnInfo = useCallback(async () => {
    try {
      const token = Cookies.get("token") || "";
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");

      const response = await apiService.get<
        ApiResponse<{ user: User; profile: Profile }>
      >("/users/who_am_i/", undefined, token);

      if (response.data.code === 200) {
        setInfoData({
          name: response.data.profile.name || "",
          email: response.data.user.email || "",
          phone: response.data.user.phone || "",
          bank: response.data.profile.bank || "카카오",
          bank_num: response.data.profile.bank_num || "",
        });
      } else {
        handleUpdateInfoResponse(response.data);
      }
    } catch (error) {
      console.error("정보 조회 중 오류 발생:", error);
    }
  }, []);

  useEffect(() => {
    handleOnInfo();
  }, [handleOnInfo]);

  const handleUpdateInfo = async (): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("name", infoData.name);
      formData.append("email", infoData.email);
      formData.append("phone", infoData.phone);
      formData.append("bank", infoData.bank);
      formData.append("bank_num", infoData.bank_num);
      const token = Cookies.get("token") || "";

      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.post<ApiResponse>(
        "/users/update_myinfo/",
        formData,
        token
      );

      handleUpdateInfoResponse(response.data);
    } catch (error) {
      console.error("정보 업데이트 중 오류 발생:", error);
    }
  };

  const handleUpdateInfoResponse = (responseData: ApiResponse) => {
    if (responseData.code === 200) {
      alert(responseData.msg);
    } else if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-16 m-20 lg:w-1/2">
      <h1 className="self-center text-3xl font-bold">내 정보</h1>
      <section className="grid grid-cols-1 py-8 gap-4">
        <FormText
          type="input"
          id="name"
          edit={true}
          title="성함"
          initialValue={infoData.name}
          onDataChange={handleDataChange}
        />
        <FormText
          type="input"
          id="email"
          edit={true}
          title="이메일"
          initialValue={infoData.email}
          onDataChange={handleDataChange}
        />
        <div className="flex flex-col justify-center items-end lg:flex-row">
          <FormText
            type="input"
            id="phone"
            edit={true}
            title="연락처"
            initialValue={infoData.phone}
            onDataChange={handleDataChange}
          />
          <button className="mb-1.5 p-2 text-sm text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90 lg:w-1/5 lg:text-base">
            인증하기
          </button>
        </div>

        <div className="flex flex-col justify-center items-start lg:flex-row lg:items-end">
          <div className="text-base text-gray-900 lg:w-1/5">
            <FormText
              type="select"
              id="bank"
              edit={true}
              title="계좌번호"
              initialValue={infoData.bank}
              onDataChange={handleDataChange}
              options={["신한", "카카오", "농협"]}
            />
          </div>
          <FormText
            type="input"
            id="bank_num"
            edit={true}
            initialValue={infoData.bank_num}
            onDataChange={handleDataChange}
          />
        </div>

        <FormText
          type="file"
          id="thumbnail"
          edit={true}
          title="썸네일"
          onDataChange={handleDataChange}
        />
      </section>
      <button
        onClick={handleUpdateInfo}
        className="w-full p-2 text-base text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90"
      >
        저장하기
      </button>
    </div>
  );
};

export default InfoModify;
