import React from "react";

export default function Terms() {
  const text = `서비스 이용 약관

  제1조 (목적)
  본 약관은 주식회사 쉐릿(이하 "회사"라 함)이 제공하는 찐(Zzin) 서비스(이하 "서비스"라 함)의 이용과 관련하여, 회사와 회원과의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 합니다.
  제2조 (용어의 정의)
  본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
  1. 유료서비스: 회원이 회사에 일정 금액을 결제하거나, 회사 또는 제3자와의 약정 조건을 수락하는 경우에 이용할 수 있는 회사의 서비스를 의미합니다.
  2. 이용계약: 회사가 제공하는 서비스를 이용하기 위해 이용자와 회사가 체결하는 계약을 의미합니다. 일반적으로 이용자가 개인정보 제공과 회사의 이용약관에 동의하는 형태로 체결됩니다.
  3. 이용자: 본 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 의미합니다.
  4. 비회원: 별도의 회원가입 없이 회사가 제공하는 서비스를 제한적으로 사용하는 이용자를 의미합니다.
  5. 회원: 본 약관에 동의함으로써 회사와 이용계약을 맺고 계정을 생성한 이용자로서, 회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있습니다.
  6. 유료회원: 월정액 요금 혹은 패키지 상품 등을 별도로 결제하여 회사의 서비스를 이용하는 회원을 의미합니다.
  7. 전자계약: 일반 계약의 구성요소인 당사자, 합의내용, 승낙표시로서의 서명 모두를 포함한 전자적 형태의 합의문서로, 서면계약과 동일한 법적 효력을 가집니다.
  8. 전자계약 생성자: 회사가 제공하는 템플릿을 사용하거나, 문서를 업로드 하여 전자계약을 생성한 회원입니다.
  9. 전자계약 대상자: 전자계약 생성자가 서명을 요청하는 대상으로서 해당 전자계약의 실제 당사자이며 서명을 입력해야 할 이용자입니다.
  10. 콘텐츠(Contents): 회원이 서비스를 사용하면서 입력 또는 게시하는 부호, 문자, 음성, 음향, 화상, 영상, 글, 사진, 동영상, 업로드된 파일, 링크 등의 모든 정보를 의미합니다.
  제3조 (약관의 게시, 개정)
  1. 회사는 본 약관의 내용과 상호, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 쉽게 확인할 수 있도록 서비스 초기화면 혹은 웹사이트에 게시합니다. 다만 약관의 경우 이용자가 연결 화면을 통해 볼 수 있도록 할 수 있습니다.
  2. 회사는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래 기본법, 전자서명법, 변호사법, 정보통신망 이용촉진 등에 관한 법률, 소비자기본법, 전기통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
  3. 회사가 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여 현행약관과 함께 웹사이트 및 서비스 내 공지사항, 이메일 발송 등 회원이 충분히 인지할 수 있는 방법으로 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 단, 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우, 적용일자 30일 전부터 공지합니다. 만약 회원 이메일의 변경 등으로 예상치 못하게 공지내용이 반송된 경우 웹사이트 및 서비스 내 공지를 함으로써 개별 통지한 것으로 간주합니다.
  4. 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 이 경우 서비스 이용을 중단하고 탈퇴를 요청할 수 있습니다. 다만, 제3항의 방법 등으로 회사가 공지 또는 통보하는 동안 개정 약관의 시행일까지 회원이 개정 약관에 동의하지 않는다는 명시적 의사표시를 하지 않는 경우, 회사는 회원이 개정 약관에 동의한 것으로 간주합니다.
  제4조 (약관의 해석)
  1. 회원이 회사와 개별계약을 체결하여 서비스를 이용하는 경우 회사는 개별 서비스에 대한 이용약관 또는 별도의 운영정책을 둘 수 있으며, 그 내용이 본 약관과 상충되는 경우 개별 서비스에 대한 이용약관 및 운영정책을 우선시합니다.
  2. 본 약관에서 정하지 아니한 사항에 관해서는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래 기본법, 전자서명법, 변호사법, 정보통신망 이용촉진 등에 관한 법률, 소비자기본법, 전기통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령 또는 상관례에 따릅니다.
  제5조 (이용계약의 성립과 제한)
  1. 서비스 이용계약은 서비스를 이용하고자 하는 자가 회사가 정한 이용약관, 개인정보 수집 및 이용에 대한 동의서에 대하여 동의 후, 회사가 제공한 양식을 작성하여 회원가입신청을 하고, 회사가 이를 승낙함으로써 성립합니다.
  2. 모든 회원은 휴대폰 본인인증을 통한 본인확인을 필수적으로 거쳐야 하며, 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다.
  3. 회사는 다음 각 호에 해당하는 신청에 대해서는 승인을 하지 않거나, 사후 이용계약을 해지할 수 있습니다.
  1) 회원가입 신청자가 본 약관에 의해 이전에 회원자격을 상실한 이력이 있는 경우. (다만 회원자격 상실 후 1년이 경과한 자로서 회사로부터 회원 재가입 승낙을 얻은 경우는 예외로 한다.)
  2) 실명이 아니거나 타인의 명의를 이용한 경우
  3) 허위정보를 기재하거나 기재누락, 오기가 있는 경우
  4) 회원가입시 정보의 불일치로 인해 휴대폰 본인인증을 통과하지 못한 경우
  5) 이미 가입된 회원과 이메일 또는 전화번호가 일치하는 경우
  6) 신용정보의 이용과 보호에 관한 법률에 의한 PC 통신, 인터넷 서비스의 신용불량자로 등록되어 있는 경우
  7) 정보통신윤리위원회에 PC 통신, 인터넷 서비스의 불량 이용자로 등록되어 있는 경우
  8) 부정한 용도와 부정한 영리를 추구할 목적으로 회사의 서비스를 이용하는 경우
  9) 만 14세 미만 아동이 정보통신망 이용촉진 및 정보보호 등에 관한 법률에서 정한 개인정보 입력 시 법정대리인의 동의를 얻지 아니한 경우
  10) 가입신청자의 귀책사유로 인하여 승인이 불가능하거나, 기타 회사가 정한 이용신청의 요건이 만족되지 않는 경우
  4. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우 가입신청에 대한 승낙을 유보할 수 있으며, 원칙적으로 그 사실을 가입신청자에게 알립니다.
  5. 이용계약의 성립시기는 회사가 가입신청자에 대한 승낙을 완료하여, 가입 신청자가 회원으로서 회사의 서비스를 사용 가능한 시점으로 정합니다.
  6. 회사는 회사의 운용 정책에 따라 회원을 등급별로 구분할 수 있으며, 서비스의 메뉴와 기능을 세분화하여, 회원의 등급별로 차등하여 제공할 수 있습니다.
  7. 본 약관은 회원이 약관을 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.
  제6조 (개인 정보의 보호 및 관리)
  1. 회사는 개인정보보호법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법 및 별도로 고지하는 개인정보 처리방침이 적용됩니다. 다만, 회사가 제공하는 공식 서비스 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
  2. 회사는 회원의 귀책사유로 인하여 노출된 회원의 모든 정보에 대해서 일체의 책임을 지지 않습니다.
  제7조 (회원의 계정 및 비밀번호 관리에 대한 의무)
  1. 회원의 편의를 위해 회원이 선정한 휴대폰 번호를 계정으로 사용합니다. 회원의 계정과 비밀번호에 관한 관리책임은 회원에게 있으며, 관리 소홀이나 제3자에게 이용을 승낙하는 등 회원의 귀책사유로 인하여 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.
  2. 회사는 회원의 계정이 반사회적 또는 미풍양속에 어긋나거나, 회사 및 회사의 운영자로 오인할 우려가 있는 경우 해당 계정의 이용을 제한할 수 있습니다.
  3. 회원은 회사가 정한 기준 안에서 자유롭게 비밀번호를 정할 수 있으며, 필요에 따라 언제든지 회원 스스로 변경할 수 있습니다. 다만, 회사는 보안 등의 이유로 회원에게 정기적 또는 비정기적으로 비밀번호의 변경을 권고할 수 있습니다.
  4. 회원은 자신의 계정과 비밀번호가 도용되었거나, 제3자가 사용하고 있음을 인지한 경우, 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
  5. 제4항의 경우 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
  제8조 (회원에 대한 통지)
  1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도의 규정이 없는 한 회사의 계정이나 전화번호 등을 발신인으로 한 회원의 전자우편, 문자 메세지, 전자쪽지, 알림, 회사의 웹사이트 등을 통한 게시의 형태로 통지할 수 있습니다.
  2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지 또는 공지사항 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
  제9조 (회사의 의무)
  1. 회사는 관련 법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다해 노력합니다.
  2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 (신용정보 포함) 보호를 위해 보안시스템을 갖춰야 하며 개인정보 취급방침을 공시하고 준수합니다.
  3. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
  제10조 (회원의 의무)
  1. 회원은 다음 행위를 하여서는 안됩니다.
  1) 신청 또는 변경 시 허위내용 등록
  2) 서비스에 게시된 정보의 허가받지 않은 변경
  3) 회사가 정한 정보 이외의 정보 (컴퓨터 프로그래밍 등) 등의 송신 또는 게시
  4) 회사와 기타 제3자의 저작권 등 지식재산권에 대한 침해
  5) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
  6) 외설 또는 폭력적인 메세지, 화상, 음성, 기타 공공질서 및 미풍양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
  7) 고객센터 상담 내용이 욕설, 폭언, 성희롱 등에 해당하는 행위
  8) 회사의 동의없이 영리를 목적으로 서비스를 사용하는 행위
  9) 허위 콘텐츠 작성 등을 통해 서비스를 부정한 목적으로 이용하는 행위
  10) 정당한 사유 없이 회사의 영업을 방해하는 내용을 기재하는 행위
  11) 리버스엔지니어링, 디컴파일, 디어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 및 기타 변형하는 행위
  12) 디도스나 해킹과 같은 불법적인 행위를 통해 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
  13) 기타 관계법령에 위반되거나 부당한 행위
  2. 회원이 제1항의 금지행위를 통해 회사에 피해를 입힌 경우 회사는 해당 회원의 서비스 이용을 제한 및 해지할 수 있으며, 해당 회원은 회사에 발생된 손해와 관련하여 법적 책임을 질 수 있습니다.
  3. 회원은 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지된 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
  제11조 (서비스 이용시간 및 중단)
  1. 회사는 회원의 회원가입을 승낙한 때부터 서비스를 개시합니다. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 단, 일부 서비스의 경우, 회사의 필요에 따라 일정시간으로 제한하여 운영될 수 있으며, 이 경우에는 그 내용을 사전에 공지합니다.
  2. 서비스 사용에 따른 고객지원이나 이용문의의 처리 등 일부 서비스는 일정시간으로 제한하여 운영할 수 있습니다.
  3. 회사는 서비스 제공에 필요한 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.
  4. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 회원에게 사전에 이를 통지하여야 하며, 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 사후에 통지할 수 있습니다.
  제13조 (서비스의 내용 및 변경)
  1. 회원은 회사가 제공하는 서비스를 이 약관, 운영정책 등 회사가 정한 규칙에 따라 이용할 수 있습니다.
  2. 회사가 회원에게 제공하는 서비스에 대하여 회사는 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다.
  3. 회사는 새로운 서비스 내용, 각종 버그 패치 등 서비스의 운영상 또는 기술상 필요한 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로 수정, 추가, 폐지 등 변경할 수 있습니다.
  4. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다. 무료로 제공되는 서비스에는 회사가 무상으로 제공한 포인트로 이용한 서비스를 포함합니다.
  5. 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
  1) 전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우
  2) 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
  3) 서비스용 설비의 보수 등의 공사와 같은 부득이한 경우
  4) 의도적인 해킹과 같은 서버 공격에 의해 서비스의 기반설비가 무력화된 경우
  6. 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중대한 과실로 인한 경우를 제외하고는 서비스의 변경 및 중지로 발생하는 문제에 대해서 책임을 부담하지 않습니다.
  제14조 (정보의 제공 및 광고의 게재)
  1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, 휴대전화, 문자메세지, SNS메세지, 카카오톡 플러스 친구 등 제3자 제공 수단의 방법으로 회원에게 제공할 수 있습니다. 회원은 관련 법령에 따른 거래관련 정보, 고객센터 답변 등을 제외한 광고의 전송에 대하여 언제든지 수신을 거절할 수 있습니다.
  2. 회사는 서비스의 운영 및 유지를 위해 서비스 화면, 홈페이지, 전자우편, 앱 등에 광고를 게재할 수 있으며, 회원은 서비스 이용시 노출되는 광고게재에 동의합니다.
  3. 회사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
  4. 회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을 메일주소 등을 활용하여 발송할 수 있으며, 회원이 원하지 않는 경우에는 언제든지 수신을 거부할 수 있습니다.
  제15조 (콘텐츠 저작권 관련)
  1. 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 회원의 콘텐츠 및 제휴협약에 따라 제공된 저작물 등은 제외합니다.
  2. 타인의 지적재산권에 속하는 대상물을 서비스 내에서 이용할 경우에는 반드시 해당 권리자의 사전 동의를 받아야 합니다.
  3. 회원이 서비스 내에 게시한 콘텐츠의 저작권은 해당 저작자에게 귀속되며 저작권 보호의 대상이 됩니다.
  4. 이용자는 서비스에 게시된 본인의 저작권을 침해한 콘텐츠를 신고하려는 경우, 전화, 이메일, 고객지원 게시판 등을 통해 신고할 수 있습니다. 회사는 서비스 내의 게시중단 요청된 게시물에 대해 최대한 빨리 게시중단 처리를 완료하고 그 결과를 통보하는 것을 원칙으로 합니다.
  제16조 (회원의 계약해제, 해지 등)
  1. 회원은 홈페이지에 개제된 회사 연락처에 연락하거나, 서비스 내 탈퇴기능을 통해 탈퇴 신청을 할 수 있으며, 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
  2. 다음 각 호에 해당하는 경우를 제외하고, 회원의 모든 정보는 탈퇴 시점 이후 바로 삭제되며 복구할 수 없습니다.
  1) 서명이 완료된 문서 및 각종정보. 단, 전자계약 생성자 및 전자계약 대상자 전원의 동의가 있을 경우 삭제 가능
  2) 관련법령 및 회사의 개인정보 처리방침에서 정한 바에 따라 특별히 회원과 관계된 정보를 저장해야 할 경우
  제17조 (이용제한 등)
  1. 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 주의, 경고, 일시정지, 영구이용정지, 계약해지 등의 조치를 즉시 취할 수 있으며, 회원은 법적 책임을 부담할 수 있습니다.
  2. 회사는 제1항의 규정에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법령을 위반한 경우에는 즉시 계약을 해지할 수 있습니다. 이에 따른 계약해지시 서비스 이용을 통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
  3. 회사가 위 제1항에 따라 회원의 서비스 이용을 제한하거나 계약을 해지하는 경우, 제한의 조건 및 세부내용은 이용제한정책 등에서 정한 바에 따르며, 회사는 제8조에서 정한 방법으로 통지합니다.
  4. 회사는 회원이 계속해서 2년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
  5. 회원은 회사의 이용제한 조치 등에 대하여 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 회원의 이의 사유가 정당하다고 인정되는 경우 회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.
  제18조 (전자문서의 법적효력)
  1. 전자서명에 사용되는 전자문서는 전자문서 및 전자거래기본법 제4조 제1항에 따라 전자적 형태로 되어 있다는 이유로 문서로서의 효력이 부인되지 않습니다. 단, 법령에서 다른 특별한 규정이 있을 경우 전자문서의 법적 효력이 인정되지 않을 수 있습니다.
  2. 전자계약 생성자는 전자서명에 사용하는 전자문서가 법령에 의해 법적 효력이 인정되지 않는 경우에 해당하는지 확인하여야 하며, 해당 사실을 인지하지 못해 발생하는 문제에 대하여 회사는 책임지지 않습니다.
  제19조 (전자서명의 법적효력)
  1. 전자서명에 사용되는 서명은 반드시 당사자의 동의가 있어야 입력되며, 전자서명법 제3조 제3항에 의해 당사자간의 약정에 따른 서명, 서명날인 또는 기명날인으로서의 효력을 가집니다.
  2. 전자서명에 사용되는 서명은 공인인증서를 사용하지 않는 일반전자서명으로써, 법령에서 공인전자서명을 사용하도록 명시한 특별한 규정이 있을 경우 서명의 법적 효력이 인정되지 않을 수 있습니다.
  3. 전자계약 생성자는 전자서명에 사용하는 서명이 법령에 의해 법적 효력이 인정되지 않는 경우에 해당하는지 확인하고 서명을 요청해야 하며, 해당 사실을 인지하지 못해 발생하는 문제에 대하여 회사는 책임지지 않습니다.
  제20조 (전자계약 대상자에 대한 확인)
  1. 전자계약 생성자는 전자계약 대상자의 실명, 생년월일, 휴대폰 전화번호를 확인하고 서명을 요청해야 합니다.
  2. 회사는 전자계약 생성자가 서명권한을 가지지 않는 전자계약 대상자에게 서명을 요청함으로서 발생하는 문제에 대해 책임지지 않습니다.
  3. 전자계약 생성자는 휴대폰 본인 인증 등과 같은 본인인증 수단을 통해 본인 확인을 할 수 있습니다.
  제21조 (유료서비스 이용 계약)
  1. 회사가 유료회원의 이용신청을 승낙한 때 (일부 서비스의 경우, 이용신청 접수 후 사전 공지한 지정된 일자)로부터 유료 서비스는 개시되며, 회사의 기술적 사유 등 사정에 의하여 유료서비스를 개시할 수 없는 경우에는 제8조의 방법에 따라 회원에게 사전 공지합니다.
  2. 회사는 회원의 유료서비스 이용신청이 있게 되는 경우, 해당 회원에게 이용신청의 내용을 통지합니다. 회사의 통지를 받은 회원은 의사표시의 불일치 등이 있는 경우 회사에 정정 또는 수정을 요청하여야 하며, 회사는 회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 본 약관의 청약 철회 등에 관한 규정에 따릅니다.
  3. 회사는 다음 각 호와 같은 유료서비스를 제공하며, 회사의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.
  1) 판매방식에 따른 분류
  (가) 직접판매: 회사가 제공하는 유료서비스를 회원에게 결제 수단을 통해 직접 제공하는 방식을 말합니다.
  (나) 대행판매: 회사가 제공하는 유료서비스를 제3자를 통해 판매하는 방식으로 해당 판매상품의 이용방식은 제휴사가 제공하는 유료서비스 안내에 따릅니다.
  2) 이용기간에 따른 분류
  (가) 정기결제형 이용권: 이용권 사용기간이 월/연 단위인 유료 서비스를 의미합니다.
  (나) 건별 이용권: 서명요청 1건당 요금을 지불하는 이용권을 의미합니다.
  3) 자동결제 종류에 따른 분류
  (가) 정기결제형 이용권(월): 매월 카드 또는 예치금을 통해 자동으로 결제, 갱신되는 유료 서비스를 말합니다.
  (나) 정기결제형 이용권(연): 매년 카드 또는 예치금을 통해 자동으로 결제되고, 매월 사용량이 충전되는 유료 서비스를 말합니다.
  4. 유료회원이 유/무선 네트워크를 통하여 서비스에 접속하거나, 유/무선 네트워크가 연결된 상태의 기기 내에 탑재된 어플리케이션을 통하여 제반 서비스를 이용하는 경우, 유료회원과 유료회원이 가입한 해당 통신사간에 체결된 통신 요금제에 의한 별도의 데이터 통화료가 발생합니다. 이 경우 발생하는 데이터 통화료는 유료회원과 해당 통신사간에 체결된 통신요금에 따라 과금/청구/수납되므로, 데이터 통화료에 대해서는 회사는 어떠한 책임도 지지 않습니다.
  제22조 (유료서비스 변경)
  1. 회사는 회원이 유료서비스 변경을 신청할 경우 즉시 반영합니다. 단, 유료서비스의 종류 및 변경 신청 시점등에 따라 유료서비스 변경 시점은 달라질 수 있습니다.
  2. 회원이 유료서비스 변경을 신청할 경우 변경 신청 전 사용하지 않는 서명 요청 건은 환불되지 않습니다.
  제23조 (결제수단)
  1. 회원은 회사에 대하여 다음과 같은 방법 중 회사가 제공하고 있는 방법으로 대금을 지급할 수 있습니다.
  1) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
  2) 전자화폐에 의한 결제
  3) 인터넷뱅킹, 폰뱅킹 등을 통한 각종 계좌이체
  4) 회사와 계약을 맺었거나 회사가 인정한 상품권 혹은 포인트에 의한 결제
  5) 직접 현금 지불
  6) 기타 전자적 지급 방법에 의한 대금 지급 등
  2. 회사는 회원이 결제수단에 대한 정당한 사용권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나, 확인이 불가한 해당 거래를 취소할 수 있습니다.
  3. 회원이 대금의 지급이나 결제를 위하여 입력한 정보에 대한 책임은 회원에게 있습니다.
  제24조 (청약 철회 및 서비스 이용계약의 해지)
  1. 유료회원은 유료서비스 공급일 이후 제공되는 서명 요청 건을 사용하지 않았을 경우 7일 이내에 회사에 청약의 철회를 요청할 수 있습니다.
  2. 유료회원이 유료서비스 공급일 이후 제공되는 서명 요청 건을 사용하지 않았더라도 청약철회가 가능한 기간 (유료서비스 공급일로부터 7일 이내)를 경과하여 청약철회를 신청하면 환불은 불가능 합니다.
  3. 유료회원이 이미 결제한 서비스 이용 계약을 해제, 해지한 경우, 남은 계약 기간 동안 유료서비스를 이용할 수 있습니다.
  4. 청약의 철회, 계약의 해제 및 해지는 유료회원이 서비스 홈페이지를 통해 할 수 있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 유료회원의 의사표시를 수령한 후 지체 없이 이러한 사실을 유료회원에게 회신합니다.
  5. 회사는 유료회원이 청약철회, 해지, 해제시 환불할 금액이 있을 경우, 원칙적으로 유료회원의 해당 의사표시를 수령한 날로부터 3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, 유료회원이 결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에 유료회원에게 전자메일, 서비스 홈페이지로 공지한 경우 및 아래의 각 호의 경우와 같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다.
  1) 신용카드 등 수납확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3 영업일 이내
  2) 결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난 환불의 경우
  3) 유료회원이 환불처리에 필요한 정보 내지 자료를 회사에 즉각 제공하지 않는 경우 (현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나, 타인 명의의 계좌를 제공하는 경우 등)
  4) 해당 회원의 명시적 의사표시가 있는 경우
  6. 회사는 콘텐츠산업 진흥법 제28조에 따라 제정된 콘텐츠이용자보호지침 등에 따라, 회사가 부담하였거나 부담할 부대비용, 수수료를 차감하여 환불할 수 있습니다.
  7. 회원이 유료서비스를 선물 받거나, 프로모션 등을 통해 무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에 대하여 회사는 회원에게 유료서비스 결제대금을 환불할 의무를 부담하지 않습니다.
  8. 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 계약을 해제, 해지하거나 서비스 이용제한, 손해배상 청구 등의 조치를 취할 수 있으며, 계약해지 후 환불하여야 할 금액이 있을 경우에는 일정한 범위 내에서 회원이 부담할 금액을 공제하고 환불합니다. 이 경우 회원은 해당 회사의 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우 서비스 이용 재개 등을 할 수 있으며, 이용자가 자신의 고의, 과실이 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.
  9. 유료회원의 신청 또는 동의에 따라 월 정기결제 중인 유료서비스의 경우, 해당 회원이 유료서비스의 이용요금을 체납하는 경우 연체가 발생한 날 자동으로 이용권 해지가 될 수 있으므로, 유료서비스를 유지하고자 하는 유료회원은 이용요금의 체납 또는 결제수단의 연체가 발생하지 않도록 사전에 조치하여야 합니다.
  10. 유료서비스를 이용중인 유료회원이 탈퇴하는 경우 유료서비스는 즉시 해제되며, 환불은 불가능합니다. 이 경우 유료회원의 정보와 서비스이용내용은 관련 법령이 정하는 경우를 제외하고는 약관 및 이 약관에 따라 처리됩니다.
  제25조 (유료서비스 가액 변경)
  회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 가액을 인상할 수 있습니다. 다만 가액이 인상되는 서비스가 정기결제형 이용권인 경우, 해당 이용권을 사용중인 회원에게 동의를 받습니다(무료체험 기간 중에 있는 경우를 포함합니다). 회원이 해당 변경에 동의하지 않는 경우 서비스 이용이 일시적으로 유보되거나 이용계약이 해지될 수 있습니다.
  제26조 (과오금의 환급)
  1. 회사는 유료서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환급합니다. 다만, 동일한 방법으로 환급이 불가능 할 때는 이를 사전에 고지하고, 가능한 방법 중 회원이 선택한 방법으로 환급합니다.
  2. 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환급합니다. 단, 회원의 귀책사유로 과오금이 발생한 경우, 회사가 과오금을 환급하는데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당 비용을 차감 후 과오금을 환급할 수 있습니다.
  3. 회사는 회원이 과오금을 주장하며 환급을 요구하는 경우, 정당하게 유료서비스 요금이 부과되었음을 입증하면 환급을 거부할 수 있습니다.
  4. 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 콘텐츠이용자보호지침이 정하는 바에 따릅니다.
  제27조 (최소 사용 환경)
  1. 이용자는 아래 각 호에 해당하는 최소 사용환경에서 서비스를 사용해야 합니다.
  1) 운영체제
  (가) Mac: Mavericks 이상
  (나) Windows: Windows 7 이상
  2) 브라우저
  (가) Internet Explorer: 11이상
  (나) Edge: 25.1 이상
  (다) Chrome: 49 이상
  (라) Safari: 9.0 이상
  (마) Opera: 37 이상
  (바) Firefox: 43 이상
  3) 쿠키: 쿠키사용은 개인의 선택에 따라 정할 수 있지만, 이에 따라 서비스의 몇몇 기능이 제대로 동작하지 않을 수 있습니다.
  2. 최소 사용 환경을 충족하지 못하는 환경에서 서비스를 이용할 경우 발생하는 문제에 대해 회사는 책임지지 않습니다.
  제28조 (손해배상)
  1. 회사가 제공하는 유료서비스의 하자 등에 의하여 회원에게 피해가 발생한 경우, 회원에 대한 피해보상의 기준, 범위, 방법 및 절차에 관한 사항은 콘텐츠이용자보호지침에 따릅니다.
  2. 회원이 본 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는 회원이 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은 회사에 대하여 손해를 배상하여야 합니다.
  3. 회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 이용자 외의 제 3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.
  제29조 (책임의 한계)
  1. 회사는 전자계약 서비스 플랫폼의 제공자로서 회원간 전자계약을 위한 시스템을 운영, 관리 및 제공할 뿐 회원을 대리하지 않고, 회원간 체결된 계약 등에 관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며 이로 인한 손해를 배상할 책임을 지지 않습니다.
  2. 회사는 천재지변 또는 국가 정책 등 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공을 일시적으로 중단할 수 있으며, 그로 인한 책임을 부담하지 않습니다.
  3. 회사는 회원의 귀책사유로 인한 서비스 중지 또는 이용의 장애가 발생하는 것에 대하여 책임을 지지 않습니다.
  4. 회사는 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이 면제됩니다.
  5. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
  6. 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에 대하여 책임을 부담하지 않습니다.
  7. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.
  제30조 (준거법 및 재판관할)
  1. 회사와 회원 간 분쟁의 해결은 대한민국 법률을 적용합니다.
  2. 회사와 회원간 발생한 소송의 관할법원은 민사소송법상의 관할법원 규정에 따릅니다.`;
  return <div className="mx-auto my-10 w-4/6 whitespace-pre-line">{text}</div>;
}
