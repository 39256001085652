import React from "react";

export default function PaymentTerms() {
  const text = `(주)쉐릿은 통신판매중개자이며, 개설된 프로젝트의 주최 당사자가 아닙니다.
  (주)쉐릿의 결제서비스를 이용하여 발생한 결제에 대하여만, 아래와 같은 취소/환불 정책을 따릅니다.
  
  
  1. 취소/환불 신청 기준
  1.  결제 일주일안에 취소 신청 시, 결제에 대하여 전액 취소가 가능합니다.
   2.  결제한 포인트에 대해서 1포인트 이상 사용 되었을 경우에는, 결제 기간과 상관없이 모두 환불처리 대상입니다.
   3. 결제 일주일 뒤부터는 잔액에 대한 환불 처리가 가능합니다.
   4. 타인으로 받은 포인트는 무료포인트로 환불대상이 아니며,상품결제를 통하여 얻은 포인트 잔액만을 대상으로 합니다.
  
  ( 0.1포인트의 가치는 100원으로 계산하여 환불됩니다)
   5. 포인트 결제시 받은 렛플력은  환불 대상금액에 비례하여 차감됩니다.     ( 0.1포인트(100원)당 4렛플력으로 계산하여 차감됩니다.)
  
  
  2. 취소/환불 방법
  1.  결제수단, 환불신청 시점, 환불 사유에 따라 환불 수수료가 부과될 수 있습니다.
   2. 환불금액이 환불수수료보다 적은 경우 , 환불이 불가능합니다.
   3. 결제수단, 환불 신청 시점에 따라 환불 방식은 결제취소 또는 환불신청으로 다를 수 있습니다.
   4. 결제취소는 PG사를 통해 환불 처리가 되는 방식으로 렛플 고객센터를 통해 처리됩니다.
   5. 환불은 렛플 고객센터로 환불 계좌를 접수하여 계좌입금 되는 환불 방식입니다.
   6. 결제 수단 별 결제 취소/환불 방식
  
   1)  신용카드
    -  렛플에서 결제여부를 확인하고 PG사를 통해 결제취소를 요청하며, 환불(한도액 복구)가 완료됩니다.
    -  결제당일 이후 결제취소 시 카드사를 통한 결제승인 취소 확인은 3~7일 가량 소요될 수 있으며, 이는 카드사 마다 다를 수 있습니다.
   2)  실시간 계좌이체
    - 렛플에서 환불 처리가 완료되면 결제에 이용한 계좌로 자동 입금되며, 평일 기준 1~2일 가량 소요될 수 있습니다.
    - 입금 시점은 은행사의 사정에 따라 달라질 수 있습니다
   3)  무통장입금(가상계좌).
       - 환불은 환불 계좌 접수 후 5~7일 내에 접수된 계좌로 취소/환불수수료를 공제한 금액으로 입금됩니다.
       - 접수 계좌 오류, 증빙서류 미접수 등의 사유로 환불이 유보 또는 지연될 수 있으며, 이 경우 이메일 등을 통해 별도 안내 합니다.
  4) 휴대전화 결제
      - 휴대전화 결제는 통신사의 약관에 따라 결제 6일이내 및 당월 결제일 경우만 취소가 가능합니다.
      - 결제 후 익월에 취소요청 시  취소가 불가능하며, 환불 접수를 통하여 환불이 가능합니다.
  
  
  
  3. 취소/환불 수수료
  1. 취소/환불 수수료는 결제수단, 환불시점, 신청상태, 환불사유에 따라 다르게 적용 및 공제됩니다.
  2. 취소/환불 수수료 공제에 대한 세금계산서 또는 현금영수증을 희망하는 경우 고객센터로 문의 바랍니다.
 
  
  
  4. 환불 계좌의 접수
  1. 환불은 반드시 신청자 본인 명의의 계좌로 접수해야 합니다.
     신청자와 명의가 일치하지 않을 경우 (주)쉐릿은 증빙 서류를 요청할 수 있습니다.
   2. 증빙서류는 가입한 아이디(이메일)로만 접수받으며 다른 이메일로 접수하거나 증빙서류 미접수 시 환불이 유보될 수 있습니다.
   3. 잘못된 환불 계좌 또는 증빙 서류를 접수하여 발생한 문제에 대하여 (주)쉐릿은 책임지지 않습니다.
  
  ※ 본인의 계좌로의 환불 접수 시 증빙 서류
  * 개인계좌 : 명의가 일치하는 신분증 사본, 통장 사본
  * 사업자계좌 : 명의가 일치하는 사업자등록증 사본, 통장 사본
  * 결제에 이용한 계좌로 환불을 희망할 경우 : 은행명, 계좌번호가 확인되는 입금확인증
  
  (시행일) 본 약관은 2024 년 05 월 01 일 부터 시행됩니다.`;
  return <div className="mx-auto my-10 w-4/6 whitespace-pre-line">{text}</div>;
}
